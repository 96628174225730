import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function NotesModal({updateType, item, style}){
  const dispatch = useDispatch();
  const companyData = useSelector((state) => state.CompanyReducer.selectedCompany);

  /// displays message from customer for processor
  const [infoNotes, setInfoNotes] = React.useState(item.notes);

  let lincenseInfo = {
    id: item.id,
    licenseNumber: item.licenseNumber,
    used: item.used,
    userId: item.userId,
    licenseUserName: `${item.userFN} ${item.userLN}`,
    licenseDayUsed: item.licenseDayUsed, 
    expirationLength: item.expirationLength, 
    licenseCreated: item.licenseCreated,
    licenseUpdated: item.licenseUpdated,
    licenseExpiration: item.licenseExpiration,
    licenseActivation: item.licenseActivation,
    notes: infoNotes,
  }

  function submitNotes(){
    if (updateType == 1){
      dispatch({ type: "UPDATE_SELECTED_COMPANY_LICENSE", payload: lincenseInfo, companyId: companyData.id });
    } else {
      dispatch({ type: "UPDATE_LICENSE", payload: lincenseInfo, companyId: companyData.id  });
    }

    setInfoNotes();
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              License Notes
            </Typography>
              <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <FormControl sx={{ m: 2 }} style={{width:'100%'}}>
                      <TextField multiline rows={10} value={infoNotes} onChange={(event)=>setInfoNotes(event.target.value)}></TextField>
                </FormControl>
                  <Button onClick={() => submitNotes()}>Update Notes</Button>
              </div>
          </Box>
        </Box>
    )
}

export default NotesModal;