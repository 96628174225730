import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import {
    Box,
    Button,
    Chip,    
    Typography,
    Modal,
    Hidden,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from "@mui/material";
import {
    CheckCircle,
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import EstimateViewer from "./SentEstimateViewer";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import SplitToken from '../../mixins/SplitToken';
import Page from '../../components/Page';

function EstimatesFromMeTable(){
    /// view all invoices sent by company to customers ///
    const user = SplitToken();
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const CompanyInvoices = useSelector((state) => state.CompanyReducer.allCompanyInvoices.invoicesDTO);
    const foundInvoiceCount = useSelector((state) => state.CompanyReducer.allCompanyInvoices.foundTotalInvoiceCount);
    const invoiceCount = useSelector((state) => state.CompanyReducer.allCompanyInvoices.totalInvoiceCount);
    const dispatch = useDispatch();
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);
    const isLoading = useSelector((state) => state.UserReducer.isDataLoading);
    
    const startDateInitialState = dayjs().subtract(1, "years").startOf('day');
    const endDateInitialState = dayjs().endOf('day');
    const [startDate, setStartDate] = React.useState(startDateInitialState);
    const [endDate, setEndDate] = React.useState(endDateInitialState);
    const [exportStartDate, setExportStartDate] = React.useState(dayjs(startDateInitialState).format());
    const [exportEndDate, setExportEndDate] = React.useState(dayjs(endDateInitialState).format());

    function resetDate(){
        setStartDate(startDateInitialState);
        setEndDate(endDateInitialState);
        setExportStartDate(startDateInitialState);
        setExportEndDate(endDateInitialState);
        dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect, startDate: exportStartDate, endDate: exportEndDate }})
    };

    const [paginationModel, setPaginationModel] = React.useState({pageSize: 25, page: 0});
    const rowCountRef = React.useRef(foundInvoiceCount || 0);
    
    const rowCount = React.useMemo(() => {
      if (foundInvoiceCount !== undefined) {
        rowCountRef.current = foundInvoiceCount;
      }
      return rowCountRef.current;
    }, [foundInvoiceCount]);

    React.useEffect(() => {
            dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect, startDate: exportStartDate, endDate: exportEndDate }})
    },[paginationModel]);

    function filterDate(){
        dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect, startDate: exportStartDate, endDate: exportEndDate }})
    };

    const [invoiceModelObj, setInvoiceModelObj] = React.useState({});
    const [invoiceModal, setInvoiceModal] = React.useState(false);
    const handleInvoiceOpen = () => setInvoiceModal(true);
    const handleInvoiceClose = () => setInvoiceModal(false);
    function showInvoice(x){
        setInvoiceModelObj({
            created: x?.created,
            customerEmail: x?.customerEmail,
            customerId: x?.customerId,
            customerName: x?.customerName,
            developerId: x?.developerId,
            developerName: x?.developerName,
            estimateId: x?.estimateId,
            fileCompanyId: x?.fileCompanyId,
            fileId: x?.fileId,
            fileIsAccepted: x?.fileIsAccepted,
            fileName: x?.fileName,
            id: x?.id,
            invoiceValues: x?.invoiceValues,
            isInvoiceAccepted: x?.isInvoiceAccepted,
            isPaidFor: x?.isPaidFor,
            isProcessed: x?.isProcessed,
            isSent: x?.isSent,
            jobId: x?.jobId,
            openInvoice: x?.openInvoice,
            pointCount: x?.pointCount,
            reportUrl: x?.reportUrl,
            sequencingErrorCode: x?.sequencingErrorCode,
            summaryUrl: x?.summaryUrl,
            updated: x?.updated,
            pointList: x?.pointList,
        })
        handleInvoiceOpen();
    };

    /// For Creating and Downloading Invoice as CSV //
    function exportToCsv() {
        let csvDownloadable = [];
        let exportECU = 0;
        let exportFinalTotal = 0;
        let exportStartDateFormat = dayjs(exportStartDate).format('MM/DD/YYYY');
        let exportEndDateFormat = dayjs(exportEndDate).format('MM/DD/YYYY');

        csvDownloadable.push(
            [`Date`,`Customer`,`Plan`,`Point Total`,`Sale Total`]
        );

        for (let i = 0; i < CompanyInvoices.length; i++){
            let data = [];
            let invoiceTotal = 0;
            let taxTotal = 0;
            let finalTotal = 0;
            if (CompanyInvoices[i]?.invoiceValues != undefined) {
                data = JSON?.parse(CompanyInvoices[i]?.invoiceValues)
        
                for (let i = 0; i < data.items.length; i++) {
                    invoiceTotal += data.items[i].total;
                }
                
                for (let i = 0; i < data.taxes.length; i++) {
                    taxTotal += Number(data.taxes[i].total);
                }
                finalTotal = taxTotal+invoiceTotal
                
                exportFinalTotal += finalTotal;
                
                if(data.pointTypeTotal){
                    exportECU += data.pointTypeTotal;
                }
            };

            csvDownloadable.push(
                [`${data?.date}`,`${CompanyInvoices[i].customerName}`,`${CompanyInvoices[i].fileName}`,`${data?.pointTypeTotal}`,`${finalTotal.toFixed(2)}`]
            );
        };
        
        csvDownloadable.push(
            [`${exportStartDateFormat} - ${exportEndDateFormat}`,``,``,`${exportECU}`,`${exportFinalTotal.toFixed(2)}`]
        );

        let csvContent = csvDownloadable.map(e => e.join(",")).join("\n");
    
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            if(customerSelect != "00000000-0000-0000-0000-000000000000"){
                navigator.msSaveBlob(blob, `${customerSelect}-${exportStartDateFormat}-${exportEndDateFormat}`);
            } else {
                navigator.msSaveBlob(blob, `allCustomerEstimates-${exportStartDateFormat}-${exportEndDateFormat}`);
            }
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                if(customerSelect != "00000000-0000-0000-0000-000000000000"){
                    link.setAttribute("download", `${customerSelect}-${exportStartDateFormat}-${exportEndDateFormat}`);
                } else {
                    link.setAttribute("download", `allCustomerEstimates-${exportStartDateFormat}-${exportEndDateFormat}`);
                }
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    React.useEffect(() => {
        if(company?.id != null){
            dispatch({ type: 'GET_COMPANY_INVOICES', payload: {companyId: company?.id, page: paginationModel.page, pageSize: paginationModel.pageSize, customerId: customerSelect, startDate: exportStartDate, endDate: exportEndDate }})
        }
    },[company]);

    const columns = [
        { field: 'customerCompany', headerName: 'Customer', flex: .7, headerClassName: 'header', editable: false },
        { field: 'fileName', headerName: 'Project Name', flex: 1, headerClassName: 'header', editable: false},
        { field: 'updated', headerName: 'Updated', flex: 1, headerClassName: 'header', renderCell: (params) => {
            let date = new Date(params.row.updated);
            return date.toLocaleString()
        }},
        { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Status(params.row)
        )},
        { field: 'total', headerName: 'Total', flex: .55, headerClassName: 'header',
        renderCell: (params) => {
            var data = JSON?.parse(params.row?.invoiceValues);

            return (<div>$ {data?.finalTotal}</div>)
    }},
        { field: 'invoice', headerName: 'Estimate', flex: 1, headerClassName: 'header', renderCell: (params) => (
            Invoice(params.row)
        )}
    ];
    function Status(x) {
            return <Chip icon={<CheckCircle/>} variant="contained" color="success" label="COMPLETE"/>
    };
    
    function Invoice(x) {
            return (
                // download invoice
                <div>       
                    <Button variant="contained" color="success" onClick={() => showInvoice(x)}>
                        View/ Download
                    </Button>
                </div>
            )
    };
    
    const rows = CompanyInvoices;

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='40%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return (
        <Page title="Sent Invoices">
        <Box style={{paddingTop:"30px", paddingRight:'30px', height: '70vh'}}>
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography textAlign={"center"} color={"blue"} variant='h4'>
                    Outgoing Invoices
                </Typography>
                <Typography textAlign={"center"}>
                    Total Invoices: {invoiceCount}
                </Typography>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box style={{display:'flex', flexDirection:'row'}}>
                    <Box sx={{ m: 1 }}>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            className='DatePicker'
                            label="Start Date"
                            value={startDate}
                            onChange={ (selectedValue) => {
                                setStartDate(dayjs(selectedValue).startOf('day'));
                                setExportStartDate(dayjs(selectedValue).startOf('day').format());
                            }}
                            renderInput={ (params) => <TextField {...params} />}
                        />
                    </Box>

                    <Box sx={{ m: 1 }} >
                        <DatePicker
                            className='DatePicker'
                            label="End Date"
                            value={endDate}
                            onChange={ (selectedValue) => {
                                setEndDate(dayjs(selectedValue).endOf('day'));
                                setExportEndDate(dayjs(selectedValue).endOf('day').format());
                            }}
                            renderInput={ (params) => <TextField {...params} />}
                        />
                    </Box>
                    <Button variant='contained' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{filterDate()}}>Filter Date Range</Button>
                    <Button variant='outlined' color='warning' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{resetDate()}}>Reset Date Range</Button>
                    <Button variant='contained' color='secondary' style={{height:'75%', alignSelf:'center', margin:'auto'}} onClick={()=>{exportToCsv()}}>Export Invoice Data</Button>
                </Box>
            </LocalizationProvider>
            <DataGrid
                columns={columns}
                rows={rows}
                components={{
                    Toolbar: CustomToolBar
                }}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 100]}
                rowCount={rowCount}
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            <Modal 
                className="invoiceModal" 
                open={invoiceModal} 
                onClose={handleInvoiceClose}
                hideBackdrop={true}    
            >
                <div>
                    <Button onClick={() => setInvoiceModal(false)}>Close</Button>
                    <EstimateViewer className="estimateDiv" item={invoiceModelObj} handleInvoiceClose={handleInvoiceClose}/>
                </div>
            </Modal>
        </Box>
        </Page>
    )
}

export default EstimatesFromMeTable;