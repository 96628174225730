import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SplitToken from '../../mixins/SplitToken';
import Toast from '../../components/Toast';
let user = SplitToken();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function Reply2Customer({item, handleClose1, handleClose2}){
    /// popout for proccessor to send reply message to customer
    const prosCompanies = useSelector((state) => state.CompanyReducer.allProsCompany);
    const [searchTerm, setSearchTerm] = useState("");
    const dispatch = useDispatch();
    const [reply, setReply] = useState(item.reply);
    let two = 2;

    var customerToSend = {
        id: item.id,
        companyId: item.companyId,
        reply: item.reply
    };
    
    function sendReply(){
        customerToSend = {
            id: item.id,
            companyId: item.companyId,
            reply: reply
        }
        if (reply == null){
            Toast.fire({
                title: 'Please Enter Message First',
                icon: 'warning'
            })
        } else {
            dispatch({ type: 'EDIT_CUSTOMER_STATUS', payload: customerToSend, handleClose1, handleClose2, two  });
        }
    };

    return (    
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                Reply to Customer
            </Typography>
            {item?.reply==null &&
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Send a one time message to get in touch with this customer
                </Typography>
            }
            {item?.reply==null ?
                <Form>
                    <TextField
                        sx={{ mt: 2 }}
                        id="outlined-multiline-static"
                        label="Reply"
                        multiline
                        rows={4}
                        value={reply}
                        onChange={(event)=> setReply(event.target.value)}
                    />   
                    <Button onClick={() => sendReply()}>Send Reply</Button>
                </Form>
                :
                <p>{reply}</p>
            }
        </Box>
    )
};

export default Reply2Customer;