import React from "react";

function PointListTable({pointList, name, id, pointTypeTotal}){

    let thousandPoints = [];
    let BMPoints = [];
    let CLPoints = [];
    let CLTotal = 0;
    let AllPointTotal = 0;

    for (let i = 0; i < pointList?.length; i++) {
        if (pointList[i]?.TypeEnum == 0){
            thousandPoints.push(pointList[i])
        }
        else if ([1, 2, 3].includes(pointList[i].TypeEnum)){
            BMPoints.push(pointList[i])
        }
        else if ((![0, 1, 2, 3].includes(pointList[i].TypeEnum)) && pointList[i]?.Enabled == true){
            CLPoints.push(pointList[i])
            CLTotal += pointList[i]?.Quantity;
        }
        if (pointList[i]?.Enabled == true){
            AllPointTotal += pointList[i]?.Quantity;
        }
    }

    return(
        <div style={{ flex: 1, margin: 2 }}>
            <div style={{ border: '2px black solid' }}>
                <div>
                    <p>Job Name: {name}</p>
                    {/* <p>Plan ID: {id}</p> */}
                </div>
                <div>
                    <p><b>Point Composition Details:</b></p>
                    {pointTypeTotal != 0 ?
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', borderTop: 'solid 3px',  borderBottom: 'solid 3px'  }}>
                            <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5 }}><span>Description:</span><span>Qty:</span></span>
                        </div>
                        {thousandPoints?.map((item, i) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {item.Enabled == true && 
                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5 }}><span><b>{item.Description}</b></span> <span>{item.Quantity}</span></span>
                            }
                            </div>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'center',  borderTop: 'solid 1px' }}>
                            <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5 }}><b>BM Point Types</b></span>
                        </div>
                        {BMPoints?.map((item, i) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {item.Enabled == true && 
                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}>
                                    { item.Description == "E types" &&
                                        <span>E</span>
                                    }
                                    { item.Description == "C types" &&
                                        <span>C</span>
                                    }
                                    { item.Description == "U types" &&
                                        <span>U</span>
                                    }
                                    <span>{item.Quantity}</span>
                                </span>
                            }
                            </div>
                        ))}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>E+C+U Total</span><span>{pointTypeTotal}</span></span>
                        </div>
                        {CLPoints.length > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'center',  borderTop: 'solid 1px' }}>
                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5 }}><b>CL Point Types</b></span>
                            </div>
                        }
                        {CLPoints?.map((item, i) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {item.Enabled == true && 
                                <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>{item.Description}</span> <span>{item.Quantity}</span></span>
                            }
                            </div>
                        ))}
                        {CLPoints.length > 0 &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5, borderTop: '1px lightgray solid' }}><span>CL Total</span><span>{CLTotal}</span></span>
                        </div>
                        }
                        <div style={{ display: 'flex', justifyContent: 'center',  borderTop: 'solid 1px' }}>
                            <span style={{ display: "flex", justifyContent: "space-between", width: "75%", margin: 5 }}><span><b>Points Total</b></span><span>{AllPointTotal}</span></span>
                        </div>
                        </div>
                        :
                        <div>
                            <h4>Botmark Processing Not Used</h4>
                        </div>
                        }
                </div>
            </div>
        </div>
    )
};

export default PointListTable;