import { React, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Page from '../../../src/components/Page';
import { styled } from '@mui/system';
import theme from '../../theme';
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import Countries from "../../mixins/Countries";
import States from "../../mixins/States";
import Toast from '../../components/Toast';

const MyPage = styled(Page)({
  backgroundColor: theme.palette.background.default,
  height: '100%',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3)
})

const RegisterView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showPassword1, setShowPassword1] = useState(false);
    const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
    const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
    const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

    const [rulerSN, setRulerSN] = useState(null);

    const [yesRuler, setYesRuler] = useState(false);
    const [noRuler, setNoRuler] = useState(false);    
    const [isProviderNeeded, setIsProviderNeeded] = useState(false);
    const [isExternal, setIsExternal] = useState(false);
    const [isValidationOnly, setIsValidationOnly] = useState(false);

    function YesRuler(){
        setYesRuler(true);
        setNoRuler(false);
        setIsProviderNeeded(false);
        setIsExternal(false);
        setIsValidationOnly(false);
    }
    function NoRuler(){
        setYesRuler(false);
        setNoRuler(true);
        setIsProviderNeeded(false);
        setIsExternal(false);
        setIsValidationOnly(false);
        setRulerSN(null);
    }
    function YesIsProviderNeeded(){
        setYesRuler(true);
        setNoRuler(false);
        setIsProviderNeeded(true);
        // setIsExternal(false);
        // setIsValidationOnly(false);
    };
    function NoIsProviderNeeded(){
        setYesRuler(true);
        setNoRuler(false);
        setIsProviderNeeded(false);
        // setIsExternal(false);
        // setIsValidationOnly(false);
    };
    // function IsExternal(){
    //     setYesRuler(false);
    //     setNoRuler(true);
    //     setIsProviderNeeded(false);
    //     setIsExternal(true);
    //     setIsValidationOnly(false);
    //     setRulerSN(null);
    // };
    // function IsValidationOnly(){
    //     setYesRuler(false);
    //     setNoRuler(true);
    //     setIsProviderNeeded(false);
    //     setIsExternal(false);
    //     setIsValidationOnly(true);
    //     setRulerSN(null);
    // };

    return (
        <MyPage title="Register" className='0' style={{height:'100vh', marginBottom:15}}>
            <Box className="1" display="flex" flexDirection="column" height="100%" justifyContent="center" style={{height:'100%'}}>
                <Container maxWidth="sm" className='2' style={{height:'100%'}}>
                    <Formik
                        initialValues={{
                            UserName: '',
                            UserEmail: '',
                            FirstName: '',
                            LastName: '',
                            Password: '',
                            Confirm: '',

                            CompanyName: '',
                            Street: '',
                            City: '',
                            State: '',
                            Zip: '',
                            Country: 'United States',
                            CompanyEmail: '',
                            AccountingEmail: '',
                            PhoneNumber: '',

                            isProviderNeeded: false,
                            // isExternal: false,
                            // isValidationOnly: false,
                            rulerSN: null,

                        }}
                        validationSchema={
                            Yup.object().shape({
                                UserName: Yup.string().max(255).required('Username is required'),
                                // UserEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                FirstName: Yup.string().max(255).required('First name is required'),
                                LastName: Yup.string().max(255).required('Last name is required'),
                                Password: Yup.string().max(255).required('Password is required'),
                                Confirm: Yup.string().max(255).required('Confirm password').oneOf([Yup.ref('Password'), null], 'Passwords must match'),

                                CompanyName: Yup.string().max(255).required('Company name required'),
                                Street: Yup.string().max(255).required('Street required'),
                                City: Yup.string().max(255).required('City required'),
                                State: Yup.string().max(255).required('State required'),
                                Zip: Yup.string().max(10).required('Zip code required'),
                                // Country: Yup.string().max(255).required('Country required'),
                                CompanyEmail: Yup.string().max(225).required('Email Required'),
                                CompanyEmail: Yup.string().max(225),
                                PhoneNumber: Yup.string().max(225).required('Phone Required'),

                                isProviderNeeded: Yup.boolean(),
                                // isExternal:  Yup.boolean(),
                                // isValidationOnly:  Yup.boolean(),
                            })
                        }
                        onSubmit={(values) => {
                            // console.log(rulerSN?.match(/[E][v][a][n][G][3][-]\d\d\d\d\d\d/gm));
                            values.Country = "United States";
                            values.UserEmail = values.CompanyEmail;
                            values.isProviderNeeded = isProviderNeeded;
                            // values.isExternal = isExternal;
                            // values.isValidationOnly = isValidationOnly;
                            if (rulerSN != null){
                                values.rulerSN = `EvanG3-${rulerSN}`;
                            } else {
                                values.rulerSN = null;
                            }
                            console.log(values);
                            // if(yesRuler == true && rulerSN?.match(/[E][v][a][n][G][3][-]\d\d\d\d\d\d/gm) == null){
                            //     Toast.fire({
                            //         title: 'Check Ruler Serial Number',
                            //         icon: 'error'
                            //     })
                            // } else{
                                dispatch({ type: "REGISTER_USER", payload: values, navigate })
                                // navigate('/', { replace: true });
                                console.log(true)
                            // }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                        <form onSubmit={handleSubmit} style={{height:'100%'}}>
                            <Box style={{border:'solid black 2px', borderRadius:'10px', padding: 15, boxShadow: '5px 5px 5px 3px rgba(168,168,168,0.45)'}}>
                            <Box mb={3}>
                                <Typography color="textPrimary" variant="h3" style={{borderBottom: 'solid grey 2px'}}>
                                    Create New Account
                                </Typography>
                            </Box>
                            <Box style={{display:'flex', flexDirection:'column'}}>
                            <Box style={{padding:5, borderBottom: 'solid grey 2px'}}>
                                        <h3 style={{borderBottom:'dashed grey 2px'}}>Company Information</h3>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.CompanyName && errors.CompanyName)}
                                            fullWidth
                                            helperText={touched.CompanyName && errors.CompanyName}
                                            label="Company Name"
                                            margin="normal"
                                            name="CompanyName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.CompanyName}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.PhoneNumber && errors.PhoneNumber)}
                                            fullWidth
                                            helperText={touched.PhoneNumber && errors.PhoneNumber}
                                            label="Company Phone"
                                            margin="normal"
                                            name="PhoneNumber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            value={values.PhoneNumber}
                                        />
                                    </Box>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.CompanyEmail && errors.CompanyEmail)}
                                            fullWidth
                                            helperText={touched.CompanyEmail && errors.CompanyEmail}
                                            label="Company Primary Email"
                                            margin="normal"
                                            name="CompanyEmail"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            value={values.CompanyEmail}
                                        />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.AccountingEmail && errors.AccountingEmail)}
                                            fullWidth
                                            helperText={touched.AccountingEmail && errors.AccountingEmail}
                                            label="Accounting Email (Optional)"
                                            margin="normal"
                                            name="AccountingEmail"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            value={values.AccountingEmail}
                                        />
                                    </Box>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.Street && errors.Street)}
                                            fullWidth
                                            helperText={touched.Street && errors.Street}
                                            label="Street"
                                            margin="normal"
                                            name="Street"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Street}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.City && errors.City)}
                                            fullWidth
                                            helperText={touched.City && errors.City}
                                            label="City"
                                            margin="normal"
                                            name="City"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.City}
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <Autocomplete
                                            fullWidth
                                            options={States}
                                            autoHighlight
                                            name="State"
                                            onChange={(e, value) => setFieldValue("State", value?.name || "")}
                                            getOptionLabel={(option) => option.name}
                                            style={{marginLeft:5, marginRight:5, marginTop:5}}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    error={Boolean(touched.State && errors.State)}
                                                    helperText={touched.State && errors.State}
                                                    label="State"
                                                    // margin="normal"
                                                    onBlur={handleBlur}
                                                    value={values.State}
                                                    />
                                                    )}
                                                />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.Zip && errors.Zip)}
                                            fullWidth
                                            helperText={touched.Zip && errors.Zip}
                                            label="Zip"
                                            margin="normal"
                                            name="Zip"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            value={values.Zip}
                                        />
                                        {/* <Autocomplete 
                                            style={{marginLeft:5, marginRight:5}}
                                            fullWidth
                                            options={Countries}
                                            name="Country"
                                            autoHighlight
                                            onChange={(e, value) => setFieldValue("Country", value?.name || "")}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={Boolean(touched.Country && errors.Country)}
                                                    helperText={touched.Country && errors.Country}
                                                    label="Country"
                                                    margin="normal"
                                                    onBlur={handleBlur}
                                                    value={values.Country}
                                                />
                                            )}
                                        /> */}
                                    </Box>
                                    {/* <Autocomplete
                                        fullWidth
                                        autoHighlight
                                        options={adminRoles}
                                        name="Role"
                                        onChange={(e, value) => setFieldValue("Role", value?.id || "")}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(touched.Role && errors.Role)}
                                                helperText={touched.Roles && errors.Roles}
                                                label="Role"
                                                margin="normal"
                                                onBlur={handleBlur}
                                                value={values.Role}
                                        />
                                        )}
                                    /> */}
                                </Box>
                                <Box style={{padding:5, borderBottom: 'solid grey 2px'}}>
                                    <h3 style={{borderBottom:'grey dashed 2px'}}>Admin Information</h3>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.FirstName && errors.FirstName)}
                                            fullWidth
                                            helperText={touched.FirstName && errors.FirstName}
                                            label="Admin First Name"
                                            margin="normal"
                                            name="FirstName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.FirstName}
                                            variant="outlined"
                                        />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.LastName && errors.LastName)}
                                            fullWidth
                                            helperText={touched.LastName && errors.LastName}
                                            label="Admin Last Name"
                                            margin="normal"
                                            name="LastName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.LastName}
                                            variant="outlined"
                                        />
                                    </Box>
                                    <h3 style={{borderBottom:'grey dashed 2px'}}>Login Information</h3>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField 
                                            style={{margin:5}}
                                            error={Boolean(touched.UserName && errors.UserName)}
                                            fullWidth
                                            helperText={touched.UserName && errors.UserName}
                                            label="Username"
                                            margin="normal"
                                            name="UserName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.UserName}
                                            variant="outlined"
                                        />
                                        {/* <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.UserEmail && errors.UserEmail)}
                                            fullWidth
                                            helperText={touched.UserEmail && errors.UserEmail}
                                            label="User Email"
                                            margin="normal"
                                            name="UserEmail"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type="email"
                                            value={values.UserEmail}
                                            variant="outlined"
                                        /> */}
                                    </Box>
                                    <Box style={{display:'flex', flexDirection:"row"}}>
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.Password && errors.Password)}
                                            fullWidth
                                            helperText={touched.Password && errors.Password}
                                            label="Password"
                                            margin="normal"
                                            name="Password"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={showPassword1 ? "text" : "password"}                                
                                            value={values.Password}
                                            variant="outlined"
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword1}
                                                    onMouseDown={handleMouseDownPassword1}
                                                    >
                                                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )}}
                                        />
                                        <TextField
                                            style={{margin:5}}
                                            error={Boolean(touched.Confirm && errors.Confirm)}
                                            fullWidth
                                            helperText={touched.Confirm && errors.Confirm}
                                            label="Confirm Password"
                                            margin="normal"
                                            name="Confirm"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            type={showPassword2 ? "text" : "password"}                                
                                            value={values.Confirm}
                                            variant="outlined"
                                            InputProps={{ // <-- This is where the toggle button is added.
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword2}
                                                    >
                                                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )}}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{margin:10}}>
                                <Label check>
                                    <b>Purchased an EvanG3 E-Ruler?</b>
                                </Label>
                                <Label check>
                                    <Input type="switch" role="switch" checked={yesRuler} value={yesRuler} onChange={()=> YesRuler()}/>
                                    Yes
                                </Label>
                                <Label check>
                                    <Input type="switch" role="switch" checked={!yesRuler} value={noRuler} onChange={()=> NoRuler()}/>
                                    No
                                </Label>

                                { yesRuler == true &&
                                    <div>
                                        <br/>
                                        <span>Please enter last 6 digits of serial Number: ex. XXXXXX</span>
                                        <TextField
                                            // {...params}
                                            // error={Boolean(touched.Country && errors.Country)}
                                            // helperText={touched.Country && errors.Country}
                                            label="Ruler Serial Number"
                                            margin="normal"
                                            onBlur={handleBlur}
                                            value={rulerSN}
                                            onChange={()=> setRulerSN(event.target.value)}
                                        />  
                                        {/* <div>             
                                            <h4>
                                                Is a Provider needed for Botmark Codes?
                                            </h4>
                                            <div>
                                            <Label check>
                                                <Input style={{margin:10}} type="switch" role="switch" checked={isProviderNeeded} value={true} onChange={()=> YesIsProviderNeeded()}/>
                                                Yes
                                            </Label>
                                            <Label check>
                                                <Input style={{margin:10}} type="switch" role="switch" checked={!isProviderNeeded} value={false} onChange={()=> NoIsProviderNeeded()}/>
                                                No
                                            </Label>
                                            </div>
                                            {/* <div>
                                            <Label check>
                                                <Input style={{margin:10}} type="switch" role="switch" checked={isXYOnly} value={isXYOnly} onChange={()=> IsXYOnly()}/>
                                                Plans Processed for X-Y Details Only.
                                            </Label>
                                            </div> */}
                                        {/* </div> */}
                                    </div>
                                }
                                {/* { noRuler == true &&
                                    <div>
                                        <div>
                                        <Label check>
                                            <Input style={{margin:10}} type="switch" role="switch" checked={isExternal} value={isExternal} onChange={()=> IsExternal()}/>
                                            Plans Processed for External Distribution.
                                        </Label>
                                        </div>
                                        <div>
                                        <Label check>
                                            <Input style={{margin:10}} type="switch" role="switch" checked={isValidationOnly} value={isValidationOnly} onChange={()=> IsValidationOnly()}/>
                                            Plans Processed for Validation Only.
                                        </Label>
                                        </div>
                                    </div>
                                } */}
                            </Box>
                            <Box my={2}>
                                <Button
                                    color="primary"
                                    // disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign up now
                                </Button>
                            </Box>
                            <Typography color="textSecondary" variant="body1">
                                Have an account?
                                {' '}
                                <Link component={RouterLink} to="/" variant="h6">
                                    Sign in
                                </Link>
                                <br/>
                                For any questions or major issues while signing up please reach out to Botmark or Layout Specialties for assistance.
                            </Typography>
                            </Box>
                        </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </MyPage>
    );
};

export default RegisterView;