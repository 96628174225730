import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    OutlinedInput
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function VideoTutorialRolesView({ item, style}){
    const dispatch = useDispatch();
    const [roleName, setRoleName] = React.useState(JSON.parse(item.vidRoles));

    let newVid ={
        Id: item.id,
        VidUrl: item.vidUrl,
        VidCategory: item.vidCategory,
        VidDescription: item.vidDescription,
        VidOrder: item.vidOrder,
        VidName: item.vidName,
        VidRoles: JSON.stringify(roleName),
    }

    function submitRoles(){
        dispatch({ type: 'UPDATE_VIDEO', payload: newVid});
    
        setRoleName([]);
    };

    const handleChange = (event) => {
        const {
        target: { value },
        } = event;
        setRoleName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    const names = [
        "Admin",
        "Planner",
        "Download",
        "Downloader Admin",
        "Dealer",
        "Recieved Billing",
        "Billing",
        "Planner Downloader",
        "Sub Super Admin",
        "No Role/ Public"
    ];

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Video Roles
            </Typography>
            <FormControl style={{width:'100%'}}>
                <InputLabel id="demo-multiple-checkbox-label">Video Roles</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={roleName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Video Roles" />}
                    renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                >
                {names.map((name) => (
                    <MenuItem key={name} value={name}>
                    <Checkbox checked={roleName.includes(name)} />
                    <ListItemText primary={name} />
                    </MenuItem>
                ))}
                </Select>
                <br/>
            </FormControl>
            <Button onClick={() => submitRoles()}>Update Video Roles</Button>
          </Box>
        </Box>
    )
};

export default VideoTutorialRolesView;