import React from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';
import "./Login.css";

function HomeAbout(){
    const opts = {
        height: '425px',
        width: '100%',
    }
    return(
        <div>
            <h1 style={{textAlign:'left', marginLeft:"10%"}}>About Botmark</h1>
            <p style={{color:"darkgray", marginRight:"10%", marginLeft:"10%", marginBottom:"50px", textAlign:"left"}}>
                Our business began (1989) as a framing subcontractor, building apartments and motels thru four stories. We also catered to the Ag industry as our equipment is well suited to provide for safe construction of clear-span buildings 70’ and wider.
                <br/>
                <br/>
                Botmark Inc. formerly Onchuck Construction has never strived to be big or above anyone else, we just believe in working hard and efficiently while caring for everyone and everything around us.
                <br/> 
                <br/> 
                We have performed for years in business with a small repetitive customer base. 
                <br/>
                <br/>
                Our company's size and efficiency has rewarded us with an ability to be innovative to develop new ideas for our business which have additionally contributed to our ability to be efficient adding to the quality of working standards desired by our employees.
                <br/>
                <br/>
                The expanded development, of our Point processor and the E-Ruler for robotic layouts (Botmark), for use by all contractors, is now part of a growing business venture. Our company wishes success to all as we deliver and share the benefits of our developments to the entire construction industry.
            </p>
            <h3 style={{textAlign:'left', marginLeft:"10%"}}>Meet the Team</h3>
            <div className="aboutDiv" style={{display:"flex", width:"75%", marginLeft:"10%", marginBottom:"20px"}}>
                <img style={{width:"196px", height:"300px", border: "solid darkgray 2px", borderRadius:"5px", boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)"}} src={DeanPhoto}/> 
                <div style={{display:"flex", flexDirection:"column", marginLeft: "20px", justifyContent:"end"}}>
                    <h3 style={{textAlign:"Left", margin:"0px"}}>Dean Onchuck</h3>
                    <h4 style={{color:"darkgrey", textAlign:"Left", margin:"0px", marginTop:"5px"}}>PRESIDENT</h4>
                    <p style={{margin:"", textAlign:"left"}}>
                        We all work everyday putting forward the best we can do with what we have. In business we all exist in this same environment.
                        <br/>
                        Changing methods to suit tomorrow is a real and difficult element in this industry.
                        <br/>
                        I have learned to teach within our methods that it is not about us being right or wrong with what we did yesterday, it is about us experiencing with our eyes open in our current environment to recognize there is always a path to a better way.
                    </p>
                </div>
            </div>
            <div className="aboutDiv" style={{display:"flex", width:"75%", marginLeft:"10%", marginBottom:"20px"}}>
                <img style={{width:"196px", height:"300px", border: "solid darkgray 2px", borderRadius:"5px", boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)"}} src={ArnelPhoto}/>
                <div style={{display:"flex", flexDirection:"column", marginLeft: "20px", justifyContent:"end"}}>
                    <h3 style={{textAlign:"Left", margin:"0px"}}>Arnel Onchuck</h3>
                    <h4 style={{color:"darkgrey", textAlign:"Left", margin:"0px", marginTop:"5px"}}>VICE PRESIDENT</h4>
                    <p style={{margin:"", textAlign:"left"}}>
                        It is exciting and challenging to be in business.
                        <br/>
                        I love working with people and helping them become more productive and in the same manner I learn from the experience of the people I work with. 
                        The need never ends to learn of new products and methods to work with.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HomeAbout;