import React from "react";
import { useRef } from "react";
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    Button,
    Tabs,
    Tab,
    CircularProgress,
    OutlinedInput,
    InputAdornment
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SaveAs from '@mui/icons-material/SaveAs';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './PaymentSummary.css';
import moment from 'moment';

function EstimateForm ({estimateObj,handleCloseEstimate}){

    const CompanyPrices = useSelector((state) => state.CompanyReducer.CompanyPrices);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const [selectedCustomer, setSelectedCustomer] = React.useState();
    const [invoiceOptions, setInvoiceOptions] = React.useState([]);
    const [invoiceTaxOptions, setInvoiceTaxOptions] = React.useState([]);
    const [invoiceTaxTotals, setInvoiceTaxTotals] = React.useState([]);
    const [totalInvoiceSum, setTotalInvoiceSum] = React.useState(0);
    const [invoiceAfterTaxSum, setInvoiceAfterTaxSum] = React.useState(0);
    const [pointTypeTotal, setPointTypeTotal] = React.useState(0);
    const [currentStatus, setCurrentStatus] = React.useState(estimateObj.isInvoiceAccepted);
    const [terms, setTerms] = React.useState('');
    const [invoiceNum, setInvoiceNum] = React.useState(0);
    const [value, setValue] = React.useState(0);

    const [customItemName, setCustomItemName] = React.useState(null);
    const [customDescription, setCustomDescription] = React.useState(null);
    const [quantity, setQuantity] = React.useState(null);
    const [customUnit, setCustomUnit] = React.useState(null);
    const [customRate, setCustomRate] = React.useState(null);
    const [updateR, setUpdateR] = React.useState();
    const [updateRStatus, setUpdateRStatus] = React.useState(false);

    const [customTotal, setCustomTotal] = React.useState(null);

    const [randomNumber, setRandomNumber] = React.useState();

    const [creditAdjustment, setCreditAdjustment] = React.useState();

    const dispatch = useDispatch();

    let totalInvoice = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    let companyId = userCompany[0].id;
    
    /// This sets the dropdown to select a customer for a plan in invoicing ///
    const allCustomers = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    
    const customers = [];
    allCustomers?.forEach(customerPusher);

    function customerPusher(allCustomers) {
        if (allCustomers.requestStatus === 1 || allCustomers.requestStatus === 2) {
        customers.push({
            id: allCustomers.id,
            companyId: allCustomers.companyId,
            created: allCustomers.created,
            deleted: allCustomers.deleted,
            message: allCustomers.message,
            requestStatus: allCustomers.requestStatus,
            userId: allCustomers.userId,
            userEmail: allCustomers.userEmail,
            customerName: allCustomers.customerName,
            userCompany: allCustomers.companyContact,
            companyStatus: allCustomers.companyStatus,
            userCompanyId: allCustomers.userCompanyId
        })}
    };

    /// For File name selection in invoice ///
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    };

    console.error(invoiceOptions)
    /// This is for selection of price options to add to an invoice ///
    const handleInvoiceChange = (event) => {

        /// event is recognized as an array. use the index of this array to grab data keys for logic statement checks ///
        if (event == 'null') {

        } else if (event == 'custom') {
            setInvoiceOptions([...invoiceOptions, {custom: true, itemName: '', description: '', unit: '', rate: 0, quantity: 0, total: 0}]);
        } else if (event[2] == 'f') {
            setInvoiceOptions([...invoiceOptions, { ...CompanyPrices[event[0]], quantity: 0, total: 0 }]);
        } else if (event[2] == 't') {
            setInvoiceTaxOptions([...invoiceTaxOptions, { ...CompanyPrices[event[0]], total: (CompanyPrices[event[0]].rate * totalInvoiceSum).toFixed(2)}]);
        }
        recalculateTotals();
    };

    /// Rerun math calculation on the estimate page ///
    function recalculateTotals() {
        taxTotal = 0;
        totalInvoice = 0;
        finalTotal = 0;

        invoiceOptions?.map((option) => totalInvoice += option.rate * option.quantity);

        for (let i = 0; i < invoiceTaxOptions.length; i++){
            invoiceTaxOptions[i].total = invoiceTaxOptions[i].rate * totalInvoice;
        };

        invoiceTaxOptions?.map((x) => taxTotal += Number(x?.total));

        finalTotal = taxTotal + totalInvoice;
        
        setTotalInvoiceSum((Number(totalInvoice)).toFixed(2));
        setInvoiceAfterTaxSum((Number(finalTotal)).toFixed(2));
    };

    const removePriceRow = (event) => {
        console.error(event);
        let tmp = [...invoiceOptions].toSpliced(event,1);

        setInvoiceOptions(tmp);
        
        setCustomDescription(null);
        setCustomItemName(null);
        setQuantity(null);
        setCustomUnit(null);
        setCustomRate(null);
        setUpdateR();
        setUpdateRStatus(false);

        setCustomTotal(null);

        recalculateTotals();
        setRandomNumber(Math.random());
    };

    const removeTaxRow = (event) => {
        let tmpTaxOptions = [...invoiceTaxOptions].toSpliced(event,1);
        let tmpTaxTotals = [...invoiceTaxTotals].toSpliced(event,1);

        setInvoiceTaxOptions(tmpTaxOptions);
        setInvoiceTaxTotals(tmpTaxTotals);
        setUpdateR();
        setUpdateRStatus(false);
        recalculateTotals();
        setRandomNumber(Math.random());
    };
    
    //// for tracking entered quantites ////
    function updateQuantityArray(item) {
        let tmp = [...invoiceOptions];
        
        if(quantity != null && customRate != null && customTotal != null){
            tmp[updateR].quantity = Number(quantity);
            tmp[updateR].rate = Number(customRate);
            tmp[updateR].total = Number(customTotal);
            if(quantity*customRate == customTotal){
                setInvoiceOptions(tmp);
            } else {
                swal({
                    title: `You have entered a custom quantity, rate, and total that do not add up. If you enter only 2 of 3 fields it will do the math for you.`,
                    position: 'center'
                });
            }
        } else if(quantity != null && customRate == null && customTotal == null){
            tmp[updateR].quantity = Number(quantity);
            tmp[updateR].rate = Number(item.rate);
            tmp[updateR].total = Number(item.rate)*Number(quantity);
            setInvoiceOptions(tmp);
        } else if(quantity == null && customRate != null && customTotal == null){
            tmp[updateR].quantity = item.quantity;
            tmp[updateR].rate = Number(customRate);
            tmp[updateR].total = Number(customRate)*Number(item.quantity);
            setInvoiceOptions(tmp);
        } else if(quantity != null && customRate != null && customTotal == null){
            tmp[updateR].rate = Number(customRate);
            tmp[updateR].quantity = Number(quantity);
            tmp[updateR].total = Number(customRate)*Number(quantity);
            setInvoiceOptions(tmp);
        } else if(quantity != null && customRate == null && customTotal != null){
            tmp[updateR].rate = Number(customTotal)/Number(quantity);
            tmp[updateR].quantity = Number(quantity);
            tmp[updateR].total = Number(customTotal);
            setInvoiceOptions(tmp);
        } else if(quantity == null && customRate == null && customTotal != null){
            tmp[updateR].rate = Number(customTotal)/Number(item.quantity);
            tmp[updateR].quantity = Number(item.quantity);
            tmp[updateR].total = Number(customTotal);
            setInvoiceOptions(tmp);
        } else {
            tmp[updateR].rate = Number(item.rate);
            tmp[updateR].quantity = item.quantity;
            tmp[updateR].total = Number(item.rate)*Number(item.quantity);
            setInvoiceOptions(tmp);
        }

        setQuantity(null);
        setCustomRate(null);
        setCustomTotal(null);
    };
    function updateCustomItemName(item) {        
        let tmp = [...invoiceOptions];

        if(customItemName != null){
            tmp[updateR].itemName = customItemName;
            setInvoiceOptions(tmp);
        } else {
            tmp[updateR].itemName = item.itemName;
            setInvoiceOptions(tmp);
        }
        
        setCustomItemName(null);
    };
    function updateCustomDescription(item) {
        let tmp = [...invoiceOptions];

        if(customDescription != null){
            tmp[updateR].description = customDescription;
            setInvoiceOptions(tmp);
        } else {
            tmp[updateR].description = item.description;
            setInvoiceOptions(tmp);
        }
        
        setCustomDescription(null);
    };
    function updateCustomUnit(item) {
        let tmp = [...invoiceOptions];

        if(customUnit != null){
            tmp[updateR].unit = customUnit;
            setInvoiceOptions(tmp);
        } else {
            tmp[updateR].unit = item.unit;
            setInvoiceOptions(tmp);
        }
        
        setCustomUnit();
    };
    
    function clearInputs(){
        setCustomItemName();
        setCustomDescription();
        setQuantity(0);
        setCustomUnit();
        setCustomRate(0);
    }

    function CheckUpdate(item){
        updateCustomItemName(item);
        updateCustomDescription(item);
        updateCustomUnit(item);
        updateQuantityArray(item);
        setUpdateR();
        setUpdateRStatus(false);
    }
    
    function UpdateRow(item){

        setCustomDescription(item);
        setCustomItemName(item);
        setCustomRate(item);
        setCustomUnit(item);
        setQuantity(item);
        setCustomTotal(item);
        
        CheckUpdate(item);
        setUpdateR();
        setUpdateRStatus(false);
        document.getElementById("nameInput").value = "";
        document.getElementById("descriptionInput").value = "";
        document.getElementById("quantityInput").value = "";
        document.getElementById("rateInput").value = "";
        document.getElementById("totalInput").value = "";
    };

    function produceInvoiceData() {

        let invoiceData = {
            date: moment().format("MM/DD/YYYY"),
            estimateNumber: invoiceNum,
            terms: terms,
            items: [],
            taxes: [],
            creditAdjustment: creditAdjustment,
            finalTotal: invoiceAfterTaxSum,
            pointTypeTotal: pointTypeTotal,
            customerInfo: selectedCustomer,
            processorInfo: userCompany[0],
            botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
        };
    
        for (let i = 0; i < invoiceOptions?.length; i++){
            invoiceData.items.push({
                companyId: invoiceOptions[i].companyId,
                itemName: invoiceOptions[i].itemName,
                description: invoiceOptions[i].description,
                unit: invoiceOptions[i].unit,
                isForTaxes: invoiceOptions[i].isForTaxes,
                isBotmarkPrimary: invoiceOptions[i].isBotmarkPrimary,
                id: invoiceOptions[i].id,
                rate: invoiceOptions[i].rate,
                created: invoiceOptions[i].created,
                updated: invoiceOptions[i].updated,
                deleted: invoiceOptions[i].deleted,
                quantity: invoiceOptions[i]?.quantity,
                total: invoiceOptions[i]?.rate * invoiceOptions[i]?.quantity
            })
        };
    
        for (let i = 0; i < invoiceTaxOptions?.length; i++){
            invoiceData.taxes.push({
                companyId: invoiceTaxOptions[i].companyId,
                itemName: invoiceTaxOptions[i].itemName,
                description: invoiceTaxOptions[i].description,
                unit: invoiceTaxOptions[i].unit,
                isForTaxes: invoiceTaxOptions[i].isForTaxes,
                isBotmarkPrimary: invoiceTaxOptions[i].isBotmarkPrimary,
                id: invoiceTaxOptions[i].id,
                rate: invoiceTaxOptions[i].rate,
                created: invoiceTaxOptions[i].created,
                updated: invoiceTaxOptions[i].updated,
                deleted: invoiceTaxOptions[i].deleted,
                quantity: null,
                total: (totalInvoiceSum * invoiceTaxOptions[i].rate).toFixed(2)
            })
        };

        return invoiceData;
    }


    console.error(estimateObj)

    function SaveInvoice(){
        let invoiceData = produceInvoiceData();
    
        let saveInvoiceObject = {
            Id: estimateObj.estimateId,
            CustomerId: selectedCustomer?.userCompanyId,
            DeveloperId: estimateObj.companyId,
            InvoiceValues: JSON.stringify(invoiceData),
            OpenInvoice: true,
            JobId: estimateObj.id,
            isSent: false,
            isInvoiceAccepted: parseInt(1)
        };

        console.error(saveInvoiceObject)

        swal({
            title: "SAVE ESTIMATE",
            text: `Save estimate for later completion.`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
        .then((willUpdate) => {
            if (willUpdate) {
                dispatch({ type: 'CREATE_INVOICE', payload: saveInvoiceObject });
                handleCloseEstimate();
            } else {
            swal({
                title: `Estimate Progress Not Saved.`,
                position: 'center'
            });
        }})
    };

    function SubmitInvoice(){

        let invoiceData = produceInvoiceData();

        let sendInvoiceObject = {
            Id: estimateObj.estimateId,
            CustomerId: selectedCustomer?.userCompanyId,
            DeveloperId: estimateObj.companyId,
            InvoiceValues: JSON.stringify(invoiceData),
            OpenInvoice: true,
            JobId: estimateObj.id,
            isSent: true,
            isInvoiceAccepted: parseInt(1)
        };

        if (selectedCustomer != undefined){
            swal({
                title: "SUBMIT ESTIMATE",
                text: `Please double check before sending estimate to ${selectedCustomer?.userCompany} : ${selectedCustomer?.customerName}.`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    dispatch({ type: 'CREATE_INVOICE', payload: sendInvoiceObject });
                    handleCloseEstimate();
            } else {
                swal({
                    title: `Estimate Not Sent.`,
                    position: 'center'
                });
            }})
        } else {
            swal({
                title: `No Customer Found, Please Select Customer.`,
                position: 'center'
            });
        }
    };

    let data;

    function initializeInvoice() {
        console.error(estimateObj)

        // if (estimateObj.invoiceJobId != null) {
        //     data = JSON.parse(estimateObj?.invoiceValues);
        //     if(!data?.items){
        //         data ={
        //             items:[],
        //             taxes:[]
        //         }
        //     }
        // } else if(estimateObj?.botmarkEstimateId === '00000000-0000-0000-0000-000000000000'){
        //     data = {
        //         date: moment().format("MM/DD/YYYY"),
        //         estimateNumber: invoiceNum,
        //         terms: terms,
        //         items: [],
        //         taxes: [],
        //         creditAdjustment: creditAdjustment,
        //         finalTotal: invoiceAfterTaxSum,
        //         pointTypeTotal: pointTypeTotal,
        //         customerInfo: selectedCustomer,
        //         processorInfo: userCompany[0],
        //         botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
        //     };
        // }else {    
            data = JSON.parse(estimateObj?.botmarkInvoiceValues);
        // }
        console.error(data)

        setSelectedCustomer(data?.customerInfo);
        setInvoiceOptions(data?.items);
        setInvoiceTaxOptions(data?.taxes);
        setTerms(data?.terms);
        setInvoiceNum(data?.estimateNumber);
        setCreditAdjustment(data?.creditAdjustment)

        invoiceTaxOptions.map((taxOption) => taxTotal += taxOption.total);

        pointCounter();

        if (estimateObj.customerId != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (estimateObj.customerId == customers[i].userId){
                    setSelectedCustomer(customers[i]);
                }
            }
        } else {
            setSelectedCustomer();
        }
    };
    
    function clearNclose() {
        handleCloseEstimate();
    };

    let pointTotal = 0;
    let pointList = "";
    if(estimateObj?.pointList != ""){
        pointList = JSON.parse(estimateObj?.pointList);
    }

    function pointCounter() {

        for (let i = 0; i < pointList?.length; i++) {
            if (pointList[i].TypeEnum == 1 || pointList[i].TypeEnum == 2 || pointList[i].TypeEnum == 3 ) {
                pointTotal += pointList[i].Quantity;
            }
        }
        setPointTypeTotal(pointTotal);
    }

    function FocusRow(item){
        setCustomItemName(item?.itemName)
        setCustomDescription(item?.description)
        setQuantity(item?.quantity)
        setCustomUnit(item?.unit)
        setCustomRate(item?.rate)
    };
    
    React.useEffect(() => {
        initializeInvoice();
    },[]);
    
    React.useEffect(() => {
        recalculateTotals();
    },[invoiceOptions,invoiceTaxOptions,totalInvoiceSum,invoiceAfterTaxSum]);

    return(
        <div>
            <Form>
                <Table>
                    <TableHead>
                        <TableCell className="estimateTableCellVariant" style={{textAlign:"center"}}>
                        {updateRStatus == true ?
                                <SaveAs color="warning"/>
                            :
                                <CheckBoxIcon color="success"/>
                        }
                        </TableCell>
                        <TableCell className="estimateTableCellVariant">Item</TableCell>
                        <TableCell className="estimateTableCellVariant">Description</TableCell>
                        <TableCell className="estimateTableCellVariant">Qty</TableCell>
                        <TableCell className="estimateTableCellVariant">U/M</TableCell>
                        <TableCell className="estimateTableCellVariant">Rate</TableCell>
                        <TableCell className="estimateTableCellVariant">Amount</TableCell>
                        <TableCell className="estimateTableCellVariant"></TableCell>
                    </TableHead>
                    <TableBody key={randomNumber}>
                        {invoiceOptions?.map((item, i) => (    
                             
                             updateR == i || updateR == null ?
                                <TableRow>
                                    {updateRStatus == true ?
                                        <TableCell className="estimateTableCellVariant">
                                            <Button value={i} onClick={()=>UpdateRow(item)}>Update Row</Button>
                                        </TableCell>
                                    :
                                        <TableCell className="estimateTableCellVariant">
                                            <Button disabled value={i} >Update Row</Button>
                                        </TableCell>
                                    }
                                    <TableCell className="estimateTableCellVariant"><input id="nameInput" placeholder={item?.itemName} onChange={(event)=> {setCustomItemName(event.target.value), setUpdateR(i), setUpdateRStatus(true)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input id="descriptionInput" placeholder={item?.description} onChange={(event)=> {setCustomDescription(event.target.value), setUpdateR(i), setUpdateRStatus(true)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input id="quantityInput" placeholder={item?.quantity} onChange={(event)=> {setQuantity(Number(event.target.value)), setUpdateR(i), setUpdateRStatus(true)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input id="unitInput" placeholder={item?.unit} onChange={(event)=> {setCustomUnit(event.target.value), setUpdateR(i), setUpdateRStatus(true)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input id="rateInput" placeholder={item?.rate} onChange={(event)=> {setCustomRate(Number(event.target.value)), setUpdateR(i), setUpdateRStatus(true)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><div style={{display:'flex', flexDirection:'row'}}><span>$</span><input id="totalInput" placeholder={item?.total.toFixed(2)} onChange={(event)=> {setCustomTotal(Number(event.target.value)), setUpdateR(i), setUpdateRStatus(true)}}></input></div></TableCell>
                                    <TableCell className="estimateTableCellVariant"><Button color="error" value={i} onClick={(event)=> removePriceRow(event.target.value)}>Remove</Button></TableCell>
                                </TableRow>
                            :
                                <TableRow>  
                                    <TableCell className="estimateTableCellVariant"> <Button value={i} disabled >Update Row</Button></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input disabled placeholder={item?.itemName} onChange={(event)=> {setCustomItemName(event.target.value)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input disabled placeholder={item?.description} onChange={(event)=> {setCustomDescription(event.target.value)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input disabled placeholder={item?.quantity} onChange={(event)=> {setQuantity(event.target.value)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input disabled placeholder={item?.unit} onChange={(event)=> {setCustomUnit(event.target.value)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><input disabled placeholder={item?.rate} onChange={(event)=> {setCustomRate(event.target.value)}}/></TableCell>
                                    <TableCell className="estimateTableCellVariant"><div style={{display:'flex', flexDirection:'row'}}><span>$</span><input disabled placeholder={item?.total.toFixed(2)} onChange={(event)=> {setCustomTotal(Number(event.target.value)), setUpdateR(i), setUpdateRStatus(true)}}></input></div></TableCell>
                                    <TableCell className="estimateTableCellVariant"><Button disabled color="error" value={i} onClick={(event)=> removePriceRow(event.target.value)}>Remove</Button></TableCell>
                                </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={7}>
                                <br/>
                                <form>
                                    <label>Add Item to Estimate:</label>
                                    <Select
                                        native
                                        onChange={(event)=> handleInvoiceChange(event.target.value)}
                                    >
                                        <option aria-label="None" value='null' />
                                        <option value='custom'>Custom</option>
                                        {CompanyPrices?.map((item, i) => (
                                            <option
                                                value={[i, item.isForTaxes]}
                                            >
                                                {item.itemName}
                                            </option>
                                        ))}
                                    </Select>
                                </form>
                                <br/>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        { estimateObj.botmarkEstimateId == "00000000-0000-0000-0000-000000000000" &&
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Base Service Fee</TableCell>
                                <TableCell><CurrencyFormat value={baseServiceFee} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></TableCell>
                                <TableCell></TableCell> 
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>{userCompany[0].name} Estimate Sub-Total</TableCell>
                            <TableCell><CurrencyFormat value={totalInvoiceSum} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></TableCell>
                            <TableCell></TableCell> 
                        </TableRow>
                        {invoiceTaxOptions?.map((item, i) => (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{item.itemName}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{item.unit}</TableCell>
                                <TableCell>{item.rate}</TableCell>
                                <TableCell><CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/></TableCell>
                                <TableCell><Button color="error" value={i} onClick={(event)=> removeTaxRow(event.target.value)}>Remove</Button></TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><b>Total</b></TableCell>
                            <TableCell><CurrencyFormat value={invoiceAfterTaxSum} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} /></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Form>
            <Button onClick={()=>recalculateTotals()}>Recalculate</Button>
            <br/>
            {  currentStatus === 3 ?
                <br/>
                :
                <Button onClick={()=>SaveInvoice()}>Save Progress</Button>
            }
            <br/>
            <Button onClick={()=>SubmitInvoice()}>Send To Customer</Button>
        </div>
    )
}

export default EstimateForm;