import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Typography,
} from "@mui/material";
import SplitToken from '../../mixins/SplitToken';
import MessageDataGrid from './MessageDataGrid';
import Page from '../../components/Page';

const CustomersPage = () => {
    /// container page for messages for customers
    const currentCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const allMessages = useSelector((state) => state.UserReducer.allUserMessages);
    const dispatch = useDispatch();
    const user = SplitToken();

    const message = [];
    const rows = message;

    /// grabs all messages that have a status of anything other than pending
    allMessages?.forEach(messagePusher);
    function messagePusher(allMessages) {
        if (allMessages?.requestStatus != 0){
        message.push({
            id: allMessages.id,
            companyId: allMessages.companyId,
            created: allMessages.created,
            deleted: allMessages.deleted,
            message: allMessages.message,
            requestStatus: allMessages.requestStatus,
            userId: allMessages.userId,
            userEmail: allMessages.userEmail,
            userFirstName: allMessages.userFirstName,
            userLastName: allMessages.userLastName,
            reply: allMessages.reply,
            companyContact: allMessages.companyContact,
            companyCity: allMessages.companyCity,
            companyCountry: allMessages.companyCountry,
            companyEmail: allMessages.companyEmail,
            companyPhoneNumber: allMessages.companyPhoneNumber,
            companyState: allMessages.companyState,
            companyStreet: allMessages.companyStreet,
            companyZip: allMessages.companyZip
        })}
    };

    useEffect(() => {
        dispatch({ type: 'FETCH_USER_MESSAGES', payload: currentCompany?.id })
    }, [])

    return(
        <Page title="Contacts">
        <Box style={{paddingTop:"30px", paddingRight:'30px'}}>            
            <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Typography textAlign={"center"} color={"blue"} variant='h4'>
                    Messages
                </Typography>
                <Typography variant="h5" >
                    Replies from Processors
                </Typography>
            </Box>
            <MessageDataGrid messages={message}/>
        </Box>
        </Page>
    )
}

export default CustomersPage;