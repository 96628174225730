import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Backdrop,
    CircularProgress,
    Tabs,
    Tab,
    Alert,
} from '@mui/material';
import swal from 'sweetalert';
// import ValidationFileName from '../billing/ValidationFileName';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useEffect } from 'react';

function StaticValidationView({handleCloseValidationView}){
    const file = useSelector((state) => state.FileReducer.file);
    const [results, setResults] = React.useState(file.file);
    const [summary, setSummary] = React.useState(file.summary);
    const [fileName, setFileName] = React.useState(file.info.name);
    const companyId = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const dispatch = useDispatch();

    const handleDownload = () => {
        dispatch({ type: "DOWNLOAD_VALIDATION", payload: file.info, company: companyId.id });
    };

    React.useEffect(() => {
        setResults(file.file);
        setSummary(file.summary);
        setFileName(file.info.name);
    }, [file.file]);

    return(
    <div>
        <Box m={2} sx={{  display: 'flex', flexDirection:'column', justifyContent: 'center', height: '100%', minHeight: '300px'}}>
            <IconButton
                edge="start"
                color="inherit"
                onClick={()=> handleCloseValidationView()}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            <h4 style={{}}>{file.info.name}</h4>
            <Button autoFocus style={{width:'50%', alignSelf:'center' }} onClick={handleDownload} variant="outlined">Download</Button>    
            <Box m={2} sx={{  display: 'flex', flexDirection:'row', justifyContent: 'center',height: '100%', minHeight: '300px'}}>
                <div style={{flex:2, margin: 20, overflowX:'scroll'}}>
                    <h4 style={{textAlign:'left'}}>Validation:</h4>
                    <pre style={{textAlign:'left'}}>
                        {results}
                    </pre>
                </div>
                <div style={{flex:1, margin:20}}>
                    <h4 style={{textAlign:'left'}}>Summary:</h4>
                        <pre style={{textAlign:'left'}}>
                            {summary}
                        </pre>
                </div>
            </Box> 
        </Box> 
    </div>
    )
};

export default StaticValidationView;