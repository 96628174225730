import React, { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import NavBar from './NavBar'
import TopBar from './TopBar';
import { styled } from '@mui/system';
import theme from '../theme';
import LoginView from '../views/auth/LoginView';
import RegisterView from '../views/auth/Register';
import BillingPage from '../views/billing';
import UserPage from '../views/users/index';
import ManageAccount from '../views/account';
import PlanPage from '../views/plans';
import UploadPage from '../views/upload';
import Videos from '../views/instructions';
import Contact from '../views/contact';
import ConfirmEmail from '../views/auth/ConfirmEmail';
import LicenseAgreement from '../views/LicenseAgreement';
import RequestPassword from '../views/auth/ForgotPasswordRequest';
import ForgotPassword from '../views/auth/ForgotPasswordSubmit';
import { useDispatch, useSelector } from 'react-redux';
import ProcessorSearch from '../views/processorSearch/ProcessorSearch';
import CustomersPage from '../views/customers/CustomersPage';
import PDFUploadPage from '../views/upload/PDFUploadPage';
import MessagePage from '../views/Messages/MessagePage';
import PreValidationPlansPage from '../views/plans/PreVlidationPlansPage';
import PlanInboxTable from '../views/plans/PlanInboxTable';
import PaymentSummary from '../views/billing/PaymentSummary';
import EstimateView from '../views/billing/EstimateView';
import EstimatesFromMeTable from '../views/billing/EstimatesFromMeTable';
import EstimatesToMeTable from '../views/billing/EstimatesToMeTable';
import CompanyViewer from '../views/company/CompanyViewer';
import LicensingPage from '../views/licensing/LicensingPage';
import RulerPage from '../views/rulers/RulerPage';
import getToken from "../mixins/GetToken";
import BotmarkEstimateTable from "../views/billing/BotmarkEstimateTable";
import MobileMenu from "./MobileMenu";
import AdminConfig from "../views/adminConfig/adminConfig";
import AppManagement from '../views/appManagement/AppManagement';
import AppDownload from '../views/appManagement/AppDownload';

import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Link,
    Backdrop,
    CircularProgress,
    Tab,
    Tabs,
    Card,
    CardHeader
} from "@mui/material";
import HomePage from "../views/auth/HomePage";
import LoginPage from "../views/auth/LoginPage";
import HomeTutorial from "../views/auth/HomeTutorial";
import HomeAbout from "../views/auth/HomeAbout";
import HomeContact from "../views/auth/HomeContact";
import "./Layout.css";

const Paper = styled('div')({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
})


const Wrapper = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
})

const ContentContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // padding: '10px'
})

const Content = styled('div')({
    flex: '1 1 auto', 
    height: '100%',
    overflow: 'auto',
})


const MainLayout = () => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(true);
    const authenticated = useSelector((state) => state.UserReducer.isAuthenticated);
    const token = getToken();
    function parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
    const pulledToken = parseJwt(token);

    function checkAuth(){
        const d = new Date(0);
        d.setUTCSeconds(pulledToken?.exp);
        
        if ( d < Date.now() ){
            navigate('/logout')
        }
    };

    let userRole = '';

    if (authenticated != false){
        userRole = authenticated?.theUserRoles[0];
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        let auth = sessionStorage.getItem('auth')
        if (auth) {
            let info = JSON.parse(auth)
            dispatch({type: "IS_AUTHENTICATED", payload: info})
            dispatch({type: "USER_DATA", payload: info.id})
            // dispatch({type: "USER_TOKEN", payload: info.token})
        }
    },[]);

    React.useEffect(() => {
        if (window.location.pathname === '/logout') {
            sessionStorage.removeItem('auth')
            dispatch({type: 'IS_AUTHENTICATED', payload: false})
            dispatch({type: 'LOGGED_OUT', payload: false})
            navigate('/')
        };
    },[window.location.pathname]);

    React.useEffect(() => {
        if (window.location.pathname === '/ConfirmEmail' || window.location.pathname === '/passwordreset' || window.location.pathname === '/requestpassword') {
            setValue(4);
        };
    },[window.location.pathname]);

    function PrivateRoute({ children }) {
        const auth = authenticated;
        return auth ? children : <Navigate to="/" />;
        // return auth ? children : <Navigate to="/login" />;
    };

    let screenWidth = screen.width;
    const [value, setValue] = React.useState(0);

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
          <div
              role="tabpanel"
              hidden={value !== index}
              id={`tabpanel-${index}`}
              {...other}
          >
              <Box style={{padding:"0px"}}>
                  {children}
              </Box>
          </div>
      )
      };
      const handleTabChange = (event, newValue) => {
          setValue(newValue);
          if (newValue == 4){
            setMobileNavOpen(true)
          } else {
            setMobileNavOpen(false)
          }
      };
      const handleMobleTabChange = (newValue) => {
          setValue(newValue);
      };

    React.useEffect(() => {
        if (authenticated == true) {
            setValue(4);
        };
    },[authenticated]);

    return (
        <Paper>
            <TopBar value={value} handleTabChange={handleTabChange} handleMobleTabChange={handleMobleTabChange} setMobileNavOpen={setMobileNavOpen} isMobileNavOpen={isMobileNavOpen}/>
            <TabPanel className="tabPanel" value={value} index={0} style={{width:"100%", overflowY:'auto'}}>
                <HomePage/>
            </TabPanel>
            <TabPanel className="tabPanel" value={value} index={1} style={{width:"100%", overflowY:'auto'}}> 
                <HomeTutorial/>
            </TabPanel>
            <TabPanel className="tabPanel" value={value} index={2} style={{width:"100%", overflow:"auto"}}> 
                <HomeAbout/>
            </TabPanel>
            <TabPanel className="tabPanel" value={value} index={3} style={{width:"100%"}}> 
                <HomeContact/>
            </TabPanel>
            { !authenticated ?
                <TabPanel className="tabPanel" value={value} index={4} style={{width:"100%", overflow:"auto"}}> 
                    {/* <LoginPage/> */}
                    <Routes>
                        <Route path='/' element={<LoginPage/>}/>
                        <Route path='/logout'/>
                        <Route path='/register' element={<RegisterView/>}/>
                        <Route path='/ConfirmEmail' element={<ConfirmEmail/>}/>
                        <Route path='/passwordreset' element={<ForgotPassword/>}/>
                        <Route path='/requestpassword' element={<RequestPassword/>}/>
                    </Routes>
                </TabPanel>
            :
                <TabPanel value={value} index={4} style={{width:"100%", overflowY:'scroll'}}> 
                    <div style={{display:'flex', flexDirection:'column'}}>
                        {screenWidth <= 900 &&
                            <MobileMenu setMobileNavOpen={setMobileNavOpen} isMobileNavOpen={isMobileNavOpen}/>
                        }
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <NavBar className="sideMenu" setMobileNavOpen={setMobileNavOpen} isMobileNavOpen={isMobileNavOpen}/>
                            <Wrapper className='Wrapper'>
                                <ContentContainer className='ContentContainer'>
                                    <Content onClick={()=>checkAuth()}>
                                        {window.location.pathname === '/login' ? <Navigate to='/'/> : ''}
                                        {/* {!authenticated && window.location.pathname === '/' ? <Navigate to='/login'/> : ''} */}
                                        {!authenticated && (window.location.pathname != '/login' && window.location.pathname != '/register' && window.location.pathname !=  '/ConfirmEmail' && window.location.pathname !=  '/passwordreset' && window.location.pathname != '/requestpassword') ? <Navigate to='/login'/> : ''}
                                        {/* <Routes>
                                            <Route path='/login' element={<LoginPage/>}/>
                                            <Route path='/logout'/>
                                            <Route path='/register' element={<RegisterView/>}/>
                                            <Route path='/ConfirmEmail' element={<ConfirmEmail/>}/>
                                            <Route path='/passwordreset' element={<ForgotPassword/>}/>
                                            <Route path='/requestpassword' element={<RequestPassword/>}/>
                                        </Routes> */}
                                        {userRole === "AD" && authenticated.isAdmin == true &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/licensing' element={<PrivateRoute><LicensingPage/></PrivateRoute>}/>
                                            <Route path='/RulerCreator' element={<PrivateRoute><RulerPage/></PrivateRoute>}/>
                                            <Route path='/PDFupload' element={<PrivateRoute><PDFUploadPage/></PrivateRoute>}/>
                                            <Route path='/companiesViewer' element={<PrivateRoute><CompanyViewer/></PrivateRoute>}/>
                                            <Route path='/employees' element={<PrivateRoute><UserPage/></PrivateRoute>}/>
                                            <Route path='/upload' element={<PrivateRoute><UploadPage/></PrivateRoute>}/>
                                            <Route path='/customers' element={<PrivateRoute><CustomersPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/planInbox' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/BotmarkInvoiceTable' element={<PrivateRoute><BotmarkEstimateTable/></PrivateRoute>}/>
                                            <Route path='/Configuration' element={<PrivateRoute><AdminConfig/></PrivateRoute>}/>
                                            <Route path="/AppManagement" element={<PrivateRoute><AppManagement/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                            
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "SSA" && authenticated.isAdmin == true &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/licensing' element={<PrivateRoute><LicensingPage/></PrivateRoute>}/>
                                            <Route path='/RulerCreator' element={<PrivateRoute><RulerPage/></PrivateRoute>}/>
                                            <Route path='/PDFupload' element={<PrivateRoute><PDFUploadPage/></PrivateRoute>}/>
                                            <Route path='/companiesViewer' element={<PrivateRoute><CompanyViewer/></PrivateRoute>}/>
                                            <Route path='/employees' element={<PrivateRoute><UserPage/></PrivateRoute>}/>
                                            <Route path='/upload' element={<PrivateRoute><UploadPage/></PrivateRoute>}/>
                                            <Route path='/customers' element={<PrivateRoute><CustomersPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/planInbox' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/BotmarkInvoiceTable' element={<PrivateRoute><BotmarkEstimateTable/></PrivateRoute>}/>
                                            <Route path='/Configuration' element={<PrivateRoute><AdminConfig/></PrivateRoute>}/>
                                            <Route path="/AppManagement" element={<PrivateRoute><AppManagement/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                            
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        { userRole === "SSA" && authenticated.isAdmin == false &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/employees' element={<PrivateRoute><UserPage/></PrivateRoute>}/>
                                            <Route path='/upload' element={<PrivateRoute><UploadPage/></PrivateRoute>}/>
                                            <Route path='/customers' element={<PrivateRoute><CustomersPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/planInbox' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "AD" && authenticated.isAdmin == false &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/employees' element={<PrivateRoute><UserPage/></PrivateRoute>}/>
                                            <Route path='/upload' element={<PrivateRoute><UploadPage/></PrivateRoute>}/>
                                            <Route path='/customers' element={<PrivateRoute><CustomersPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/planInbox' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "PL" &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/upload' element={<PrivateRoute><UploadPage/></PrivateRoute>}/>
                                            <Route path='/customers' element={<PrivateRoute><CustomersPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/planInbox' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "PD" &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanPage/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "DAD" &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/employees' element={<PrivateRoute><UserPage/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/InvoicesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/InvoicesFromMe' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/findProcessor' element={<PrivateRoute><ProcessorSearch/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "DO" &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><PlanInboxTable/></PrivateRoute>}/>
                                            <Route path='/contacts' element={<PrivateRoute><MessagePage/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/licenseAgreement' element={<LicenseAgreement/>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "BI" &&
                                        <Routes>
                                            <Route path='/EstimatesToMe' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/' element={<PrivateRoute><EstimatesFromMeTable/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                        {userRole === "RBI" &&
                                        <Routes>
                                            <Route path='/' element={<PrivateRoute><EstimatesToMeTable/></PrivateRoute>}/>
                                            <Route path='/instructions' element={<PrivateRoute><Videos/></PrivateRoute>}/>
                                            <Route path='/contact' element={<PrivateRoute><Contact/></PrivateRoute>}/>
                                            <Route path='/account' element={<PrivateRoute><ManageAccount/></PrivateRoute>}/>
                                            <Route path="/AppDownload" element={<PrivateRoute><AppDownload/></PrivateRoute>}/>
                                        </Routes>
                                        }
                                            
                                        {/* <Route path='/billing' element={<PrivateRoute><BillingPage/></PrivateRoute>}/> */}
                                        {/* <Route path='/preValidation' element={<PrivateRoute><PreValidationPlansPage/></PrivateRoute>}/> */}
                                        {/* <Route path='/estimateCreator' element={<PrivateRoute><PaymentSummary/></PrivateRoute>}/> */}
                                        {/* <Route path='/estimateView' element={<PrivateRoute><EstimateView/></PrivateRoute>}/> */}
                                    </Content>
                                </ContentContainer>
                            </Wrapper>
                        </div>
                    </div>
                </TabPanel>
}
        </Paper>
  );
};

export default MainLayout;