import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete
} from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function RulerCompanyModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
//   const [infoNotes, setInfoNotes] = React.useState(item.rulerNotes);
  const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
  const [selectedComp, setSelectedComp] = React.useState(item.userId);

    /// sorts array alphabetically for user ease of use
    let allCompaniesArray = allCompanies.sort(function(a, b){
      var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
      if(nameA < nameB) { return -1; }
      if(nameA > nameB) { return 1; }
      return 0;
    });

    const startDateInitialState = dayjs().startOf('year');
    const endDateInitialState = dayjs().endOf('year');
    const [startDate, setStartDate] = React.useState(startDateInitialState);
    const [endDate, setEndDate] = React.useState(endDateInitialState);
    const [exportStartDate, setExportStartDate] = React.useState(dayjs(startDateInitialState).format());
    const [exportEndDate, setExportEndDate] = React.useState(dayjs(endDateInitialState).format());

    function filterCompany(){
        dispatch({ type: 'GET_COMPANIES_FILTERED', payload: {startDate: exportStartDate, endDate: exportEndDate}});
    };

    function resetCompany(){
        dispatch({ type: 'GET_ALL_COMPANIES' });
    };

  let rulerInfo = {
    Id: item.id,
    RulerNotes: item.rulerNotes,
    RulerSerialNumber: item.rulerSerialNumber,
    userId: selectedComp,
    WarrantyDate: item.warrantyDate,
  }

  function submitCompany(){
    dispatch({ type: "UPDATE_RULER", payload: rulerInfo });

    setSelectedComp(item.userId);
  };

  const defaultProps = {
    options: allCompaniesArray,
    getOptionLabel: (option) => option.name,
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Ruler Company Assignment
            </Typography>
            <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <FormControl style={{width:'100%', margin:'2px'}}>
                    <Autocomplete
                        disablePortal
                        {...defaultProps}
                        renderInput={(params) => <TextField value={params.id} {...params} label="Companies" />}
                        onChange={(event,newValue) => setSelectedComp(newValue?.id)}
                    />
                </FormControl>
                <div style={{width:"100%", margin:'5px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box>
                            <DatePicker
                                className='DatePicker'
                                views={['year']}
                                label="Year"
                                value={endDate}
                                onChange={ (selectedValue) => {
                                    setStartDate(dayjs(selectedValue).startOf('year'));
                                    setExportStartDate(dayjs(selectedValue).startOf('year').format());
                                    setEndDate(dayjs(selectedValue).endOf('year'));
                                    setExportEndDate(dayjs(selectedValue).endOf('year').format());
                                }}
                                renderInput={ (params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Button variant='contained' style={{ margin:"5px"}} onClick={()=>{filterCompany()}}>Filter Company List</Button>
                    <Button variant='contained' style={{margin:'5px'}} onClick={()=>{resetCompany()}}>Reset Company List</Button>
                </div>
                <Button onClick={() => submitCompany()}>Update Company</Button>
            </div>
          </Box>
        </Box>
    )
}

export default RulerCompanyModal;