import RulerCreator from './RulerCreator';
import RulerDataGrid from './RulerDataGrid';
import Page from '../../components/Page';
import {
    Box,
    Tabs,
    Tab,
} from '@mui/material';
import React from 'react';

function RulerPage(){
    /// Ruler Page

    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <Page title="Rulers">
            <Tabs value={value} onChange={handleTabChange} left style={{ borderBottom: '2px solid darkGray', paddingBottom: '10px' }}>
                <Tab label="Ruler Table" />
                <Tab label="Ruler Generator" />
            </Tabs>
            <TabPanel value={value} index={0} style={{textAlign:'left'}}>
                <RulerDataGrid/>
            </TabPanel>
            <TabPanel value={value} index={1} style={{textAlign:'left'}}>
                <RulerCreator/>
            </TabPanel>
        </Page>
    )
};

export default RulerPage;