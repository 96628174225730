import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Input,
    TextField,
    Tabs,
    Tab,
    Autocomplete,
    Checkbox,
    OutlinedInput,
    MenuItem
} from '@mui/material';

function VideoCategoriesManager(){
    const dispatch = useDispatch();
    const vidCats = useSelector((state) => state.FileReducer.vidCats);
    const [vidCat, setVidCat] = React.useState('');
    const [vidCatNumber, setVidCatNumber] = React.useState('');
    const [vidCatId, setVidCatId] = React.useState();

    const [updateR, setUpdateR] = React.useState();
    const [updateRStatus, setUpdateRStatus] = React.useState(false);

    let objToSend = {
        Id: vidCatId,
        VideoCategoryName: vidCat,
        VideoCategoryOrder: vidCatNumber
    }

    function UpdateRow(){
        dispatch({ type: 'UPDATE_VIDEO_CATEGORY', payload: objToSend})
        setUpdateR();
        setUpdateRStatus(false);
    }

    function DeleteCat(item){
        swal({
            title: "DELETE VIDEO",
            text: `Delete this video forever? This cannot be undone. Note: Video will still be stored on Youtube.`,
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
        .then((willUpdate) => {
            if (willUpdate) {
                dispatch({ type: 'DELETE_VIDEO_CATEGORY', payload: item.id})
                setUpdateR();
                setUpdateRStatus(false);
                setVidCat('');
                setVidCatNumber('');
                setVidCatId();
            } else {
                swal({
                    title: `Category not Deleted.`
                });
                setUpdateR();
                setUpdateRStatus(false);
                setVidCat('');
                setVidCatNumber('');
                setVidCatId();
            }
        })
    }

    function CancelEdit(){
        setUpdateR();
        setUpdateRStatus(false);
        setVidCat('');
        setVidCatNumber('');
        setVidCatId();
    }

    React.useEffect(() => {
    },[vidCats]);

    return(
        <div style={{textAlign:'left'}}>
            <h4>Video Categories</h4>
            <div style={{textAlign:'left'}}>
            {vidCats.map((item,i) => (
                                            
                updateR == i ?
                <div>
                    <TextField style={{marginRight:'10px'}} defaultValue={item?.videoCategoryName} onChange={(event) => setVidCat(event.target.value)}></TextField>
                    <TextField type="number" defaultValue={item?.videoCategoryOrder} onChange={(event) => setVidCatNumber(event.target.value)}></TextField>
                    {updateRStatus == true ?
                        <div>
                            <Button style={{marginRight:'10px'}} value={i} onClick={()=>UpdateRow(item)}>Update Row</Button>
                            <Button style={{marginRight:'10px'}} variant="contained" sx={{ bgcolor: `secondary.light`}} value={i} onClick={()=>CancelEdit(item)}>Cancel</Button>
                            <Button style={{marginRight:'10px'}} className="delBtn" variant="contained" sx={{ bgcolor: `error.light`}} value={i} onClick={()=>DeleteCat(item)}>Delete</Button>
                        </div>
                    :
                        <div>
                            <Button value={i} onClick={()=>{setUpdateR(i), setUpdateRStatus(true), setVidCat(item.videoCategoryName), setVidCatNumber(item.videoCategoryOrder), setVidCatId(item.id)}}>Select Row</Button>
                        </div>
                    }
                </div>
                :
                <div>
                    <TextField style={{marginRight:'10px'}} disabled defaultValue={item?.videoCategoryName} onChange={(event) => setVidCat(event.target.value)}></TextField>
                    <TextField disabled type="number" defaultValue={item?.videoCategoryOrder} onChange={(event) => setVidCatNumber(event.target.value)}></TextField>
                        <div>
                            <Button value={i} onClick={()=>{setUpdateR(i), setUpdateRStatus(true),  setVidCat(item.videoCategoryName), setVidCatNumber(item.videoCategoryOrder), setVidCatId(item.id)}}>Select Row</Button>
                        </div>
                </div>
            ))} 
            </div>
            <br/>
            <br/>
        </div>
    )
};

export default VideoCategoriesManager;