import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Hidden,
    IconButton,
    Toolbar,
    colors,
    Badge,
    Tabs,
    Tab,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Grow,
    Button,
    ClickAwayListener
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../src/components/Logo';
import { useSelector, useDispatch } from 'react-redux';
import "./Layout.css";
import NavBar from './NavBar/index';

const TopBar = ({ onMobileNavOpen, value, handleTabChange, handleMobleTabChange, setMobileNavOpen, isMobileNavOpen }) => {
    const [notifications] = useState([{}, {}]);
    const auth = useSelector((state) => state.UserReducer.isAuthenticated);
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }
  
    // return focus to the button when we transitioned from !open -> open
    // const prevOpen = React.useRef(open);
    // React.useEffect(() => {
    //   if (prevOpen.current === true && open === false) {
    //     anchorRef.current.focus();
    //   }
  
    //   prevOpen.current = open;
    // }, [open]);

    
    function notif() {
        if (auth) {
            return (
                <IconButton color="inherit" size="large">
                    <Badge
                        badgeContent={notifications.length}
                        color="warning"
                        variant="standard"
                        >
                        <Notifications color="white" />
                    </Badge>
                </IconButton>
            )
        }
    }
    
    let screenWidth = screen.width;

    return (
        <AppBar
            elevation={0}
            sx={{ backgroundColor: colors.grey[900] }}
        >
            <Toolbar style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <RouterLink to="/" className="logo">
                    <Logo className="logoImage"/>
                </RouterLink>
                {screenWidth <= 900 ?
                    <div className='mobileTabs'>
                        <Button className="mobileTabButton" value={0} onClick={()=>{ handleMobleTabChange(0)}}>Home</Button>
                        { auth &&
                            <Button className="mobileTabButton" value={4} onClick={()=>{ handleMobleTabChange(4)}}>Portal</Button>
                        }
                        <Button
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            className="mobileTabs mobileTabButton"
                            sx={{border:"solid white 1px !important", borderRadius:"5px"}}
                        >
                            Menu
                        </Button>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            placement="bottom-start"
                            transition
                            disablePortal
                            >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            {/* <MenuItem value={0} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>Home</MenuItem> */}
                                            <MenuItem value={1} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>Tutorials</MenuItem>
                                            <MenuItem value={2} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>About</MenuItem>
                                            <MenuItem value={3} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>Contact</MenuItem>
                                            {/* { auth &&
                                                <MenuItem value={4} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>Portal</MenuItem>
                                            } */}
                                            {/* { !auth ?
                                                <MenuItem value={4} onClick={(event)=>{handleClose, handleMobleTabChange(event.target.value)}}>Login</MenuItem>
                                                :
                                                <MenuItem value={5} onClick={() => dispatch({type:'USER_LOGOUT'})}>Logout</MenuItem>
                                            } */}
                                        </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        {/* { auth && 
                            <IconButton sx={{}} color="inherit" onClick={()=>setMobileNavOpen(!isMobileNavOpen)} size="large">
                                <MenuIcon />
                            </IconButton>
                        } */}
                        { !auth ?
                            <Button className="mobileTabButton" value={4} onClick={()=>{ handleMobleTabChange(4)}}>Login</Button>
                            :
                            <Button href="/" className="mobileTabButton" value={4} onClick={() => dispatch({type:'USER_LOGOUT'})}>Logout</Button>
                        }
                    </div>
                    :
                    <div className='tabParent'>
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="Home" className="tabChild" />
                            <Tab label="Tutorials" className="tabChild" />
                            <Tab label="About" className="tabChild" />
                            <Tab label="Contact" className="tabChild" />
                            { auth &&
                                <Tab label="Portal" className="tabChild" />
                            }
                            { !auth ?
                                <Tab value={4} label="Login" className="tabChild" />
                                :
                                <Tab href="/" value={4} onClick={() => dispatch({type:'USER_LOGOUT'})} label="Logout" className="tabChild" />
                            }
                        </Tabs>
                    </div>
                }
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;