import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
    Autocomplete
} from "@mui/material";

function VideoCatManager({item, style}){
    const dispatch = useDispatch();
    const [vidCat, setVidCat] = React.useState(item.vidCategory);
    const vidCats = useSelector((state) => state.FileReducer.vidCats)

    let newVid ={
        Id: item.id,
        VidUrl: item.vidUrl,
        VidCategory: vidCat,
        VidDescription: item.vidDescription,
        VidOrder: item.vidOrder,
        VidName: item.vidName,
        VidRoles: item.vidRoles,
    }

    function submitNotes(){
        dispatch({ type: 'UPDATE_VIDEO', payload: newVid});
    
        setVidCat();
    };

    const defaultProps = {
        options: vidCats,
        getOptionLabel: (option) => option.videoCategoryName,
    };

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
            <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
                Video Categories
            </Typography>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <div style={{width:'100%'}}>
                    <Autocomplete
                        disablePortal
                        {...defaultProps}
                        renderInput={(params) => <TextField value={params.id} {...params} label="Video Category" />}
                        // value={vidCat}
                        onChange={(event,newValue) => {setVidCat(newValue?.id)}}
                    />
                </div>
                <Button onClick={() => submitNotes()}>Update Video Categories</Button>
            </div>
            </Box>
        </Box>
    )
};

export default VideoCatManager;