import LicenseDataGrid from './LicenseDataGrid.jsx';
import LicensingCreation from './licensing.jsx';
import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Tabs,
    Tab,
} from '@mui/material';

function LicensingPage (){
    const dispatch = useDispatch();
    /// licensing Page

    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch({ type: 'GET_LICENSES', payload: {companyId: "00000000-0000-0000-0000-000000000000", type: '2'} });
        dispatch({ type: 'GET_ALL_COMPANIES' })
    }, []);

    return(
        <Page title="Licensing">
            <div style={{width: "100%", height: "100%"}}>
            <Tabs value={value} onChange={handleTabChange} left style={{ borderBottom: '2px solid darkGray', paddingBottom: '10px' }}>
                    <Tab label="License Table" />
                    <Tab label="License Generator" />
            </Tabs>
            <TabPanel value={value} index={0} style={{textAlign:'left'}}>
                <LicenseDataGrid/>
            </TabPanel>
            <TabPanel value={value} index={1} style={{textAlign:'left'}}>
                <LicensingCreation/>
            </TabPanel>
            </div>
        </Page>
    )
}

export default LicensingPage;