import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
} from "@mui/material";

function CustomerStatusChange({item, style, handleClose1, handleClose2}){
    /// allow for processor to set the status of the customer [pending/approved with credit/approved with out credit/ denied]
    const dispatch = useDispatch();
    const [status, setStatus] = React.useState(item?.requestStatus);
    const handleChange = (event) => {
        setStatus(Number(event.target.value));
    };

    let one = 1;

    function updateCustomerStatus(item){
        var customerToSend = {
            id: item.id,
            companyId: item.companyId,
            message: item.message,
            requestStatus: status,
        }
        dispatch({ type: 'EDIT_CUSTOMER_STATUS', payload: customerToSend, handleClose1, handleClose2, one });
    }

    return(
        <Box sx={style}>
            <Typography>Set Status</Typography>
                <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl sx={{ m: 2, minWidth: 120 }}>
                        <InputLabel htmlFor="demo-dialog-native">Status</InputLabel>
                        <Select
                            native
                            value={status}
                            onChange={ handleChange}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Pending</option>
                            <option value={1}>Approved w/ Invoice</option>
                            <option value={2}>Approved w/ Credit Card</option>
                            <option value={3}>Denied</option>
                        </Select>
                        <Button onClick={()=>updateCustomerStatus(item)}>Update Status</Button>
                    </FormControl>
                </Box>
        </Box>
    )
}

export default CustomerStatusChange;