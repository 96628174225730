import React, { useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Input,
    TextField
} from '@mui/material';
import { Folder } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';

function AppManagement(){
    ///// PDF Upload form select relevant pdf type to display it in the correct location on the website /////
    const navigate = useNavigate();
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const appUrls = useSelector((state) => state.FileReducer.appUrls);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [file, setFile] = React.useState([]);
    const dispatch = useDispatch();
    const [markedVersion, setMarkedVersion] = React.useState();

    let highestVersion = {};

    // let data = appUrls;
    function FindHighest(){
        highestVersion = appUrls?.reduce((a, b) =>
            0 < a?.appVersion.localeCompare(b?.appVersion, undefined, { numeric: true, sensitivity: 'base' })
                ? a
                : b
        )
        console.log(highestVersion)
        setMarkedVersion(highestVersion)
    }

    React.useEffect(() => {
        setFile(acceptedFiles)
        dispatch({ type: 'GET_ALL_APPS' })
        if (appUrls.length != 0){
            FindHighest()
        }
    },[acceptedFiles],[]);

    const files = acceptedFiles.map(file => (
        <Tooltip key={file.path} title="Click to select different file">
            <ListItem sx={{ border: '1px solid black' }}>
                    <ListItemIcon>
                        <Folder/>
                    </ListItemIcon>
                    <ListItemText>
                        {file.path} - {file.size} bytes
                    </ListItemText>
            </ListItem>
        </Tooltip>
    ));

    function deleteApp(item){
        dispatch({ type: 'DELETE_APP', payload: item})
    }

    const handleUpload = () => {
        if ( file.length < 1 ) return Toast.fire({ title: 'File required', icon: 'warning' });
        else if (!file[0].name.includes('.zip')) return Toast.fire({ title: '.ZIP FILES ONLY', icon: 'warning' });
        else dispatch({ type: 'APP_UPLOAD', payload: {file: file[0], name: file[0].name, companyId: company.id}, setFile, navigate })
    };

    return (
        <Page title="App Management">
        <Box sx={{margin: "5%"}}>
            <Box style={{border:'solid black 2px'}}>
                <h2 style={{margin:'5px'}}>App Uploads</h2>
                <p>Select App Version to upload it.</p>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Box 
                        sx={{ 
                            width: '80%',
                            height: '70%',
                            display: 'flex',
                            justifyContent: 'center', 
                            alignItems: 'center',
                            borderWidth: '2px',
                            borderRadius: '2px',
                            borderStyle: 'dashed',
                            padding: '20px',
                            transition: 'border .24s ease-in-out'
                        }}
                        {...getRootProps({className: 'dropzone'})}
                    >
                        <input disabled={company === undefined} {...getInputProps()}/>
                        { acceptedFiles.length > 0 ? <List>{files}</List> : <p>Drag 'n' drop app .zip file here, or click to select an app .zip file</p> }
                    </Box>
                </Box>
                <Box sx={{width:"100%", height:"100%", marginTop: '5%'}}>
                    <Button sx={{ width: '50%', margin: "10px" }} 
                        variant="contained" 
                        onClick={handleUpload}
                    >
                        Upload App
                    </Button>
                </Box>
            </Box>
            <p>Note: After deleting an App version it wont be visible here and therefore not visible to users to download, but will still be stored on the Cloud.</p>
            <ul className="processorList" style={{padding: 'inherit'}}>
                {
                    appUrls?.map((app, i) => {
                        return (
                            <div key={i} i={i} className="processorIndex">
                                { app?.appVersion == markedVersion?.appVersion ?
                                <div style={{border: "green solid 2px", borderRadius:'5px'}}>
                                    <p style={{color:'green'}}>LATEST</p>
                                    <h3>{app?.appVersion.split(".Installer.zip")}</h3>
                                    <Button href={app?.url} >Download</Button>
                                    <Button onClick={() => deleteApp(app.id)}>Delete</Button>
                                </div>
                                :
                                <div>
                                    <h3>{app?.appVersion.split(".Installer.zip")}</h3>
                                    <Button href={app?.url} >Download</Button>
                                    <Button onClick={() => deleteApp(app.id)}>Delete</Button>
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </ul>
        </Box>
        </Page>
    )
};

export default AppManagement;
