import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  css
} from "@mui/material";
import Youtube from 'react-youtube';
import "./Login.css";

function HomeTutorial(){
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    const dispatch = useDispatch();

    const vids = useSelector((state) => state.FileReducer.vids);
    const vidCats = useSelector((state) => state.FileReducer.vidCats);

    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };
    const [vidsToShow, setVidsToShow] = React.useState([]);
    const [splittedArrays, setSplittedArrays] = React.useState([]);
    const [catsToShow, setCatsToShow] = React.useState([]);
    
    function initTutorialDisplay(){
        vids?.forEach(setVidData);
        function setVidData(item){
            let foundRoles = JSON.parse(item?.vidRoles);
            for (let e = 0; e < vidCats?.length; e++) {
                if (vidCats[e]?.id == item?.vidCategory){
                    let foundCatName = vidCats[e]?.videoCategoryName;
                    let foundCatOrder = vidCats[e]?.videoCategoryOrder;
                    for (let i = 0; i < foundRoles?.length; i++){
                        if (foundRoles[i] == "No Role/ Public"){
                            vidCats[e] = {
                                id: vidCats[e].id,
                                videoCategoryName: vidCats[e].videoCategoryName,
                                videoCategoryOrder: vidCats[e].videoCategoryOrder,
                                hasVideos: true
                            }
                            vidsToShow.push({
                                id: item.id,
                                vidUrl: item.vidUrl,
                                vidCategory: item.vidCategory,
                                vidDescription: item.vidDescription,
                                vidOrder: item.vidOrder,
                                vidName: item.vidName,
                                vidRoles: item.vidRoles,
                                vidCatName: foundCatName,
                                vidCatOrder: foundCatOrder,
                            })
                        }
                    }
                }
            }
        }

        const solidVidCats = [...vidCats];
        if(solidVidCats?.length != 0){
            setCatsToShow(solidVidCats?.sort((a,b) => a?.videoCategoryOrder - b?.videoCategoryOrder));
        }
        const solidVids = [...vidsToShow];
        if(solidVids?.length != 0){
            setVidsToShow(solidVids?.sort((a,b) => a?.vidOrder - b?.vidOrder));
        }
    }

    function splitArray(arr, key) {
        vidsToShow?.sort((a,b) => a?.vidCatOrder - b?.vidCatOrder);
        const result = {};
    
        arr?.forEach(item => {
            const value = item[key];
            if (!result[value]) {
                result[value] = [];
            }
            result[value].push(item);
        });
    
        return Object.values(result);
    }

    function sortVids(){
        const solidSplittedArrays = [...vidsToShow]
        setSplittedArrays(splitArray(solidSplittedArrays, 'vidCatOrder'));
    }
    
    const opts = {
        height: '300px',
        width: '100%',
    }

    React.useEffect(() => {
        dispatch({ type: 'GET_VIDEO_URLS'});
        dispatch({ type: 'GET_VIDEO_CATS'});
        initTutorialDisplay();
        sortVids();
    },[]);
    
    React.useEffect(() => {},[vids,vidCats]);

    return (
        <div>
            <div style={{margin:'2%', justifyItems:'center'}}>
                <Typography variant='h3' textAlign="left" marginBottom="20px">
                    Tutorials
                </Typography>
                <Grid container spacing={3} style={{margin:'auto', width:'auto'}}>
                    {catsToShow?.map((a,b) =>(
                        <>
                        {a?.hasVideos == true &&
                        <Accordion className="vidAccordion" style={{display:'flex', flexDirection:'column', border:'solid darkGray 2px', width:"100%", padding:5, borderRadius:"5px", margin:'5px'}} expanded={expanded === b} onChange={handleChange(b)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <h3 style={{textDecoration:'underline'}}>{a?.videoCategoryName}</h3>
                            </AccordionSummary>
                            <AccordionDetails style={{justifyItems:'center'}}>
                            {vidsToShow?.map((y,e) => (
                                <>
                                {y?.vidCategory == a?.id &&
                                    <Card className="youtubeCard" style={{margin:"10px", width:"75%"}}>
                                        <CardHeader sx={{}} title={y?.vidName}/>
                                        {/* <span style={{}}><b>Video #:{y?.vidOrder}</b></span> */}
                                        <br/>
                                        <p style={{margin:'10px'}}>Description: {y?.vidDescription}</p>
                                        <div className="youtubeDiv" style={{width:'50%', justifySelf:'center'}}>
                                            <Youtube style={{}} videoId={y?.vidUrl} opts={opts} />
                                        </div>
                                    </Card>
                                }
                                </>
                            ))}
                            </AccordionDetails>
                        </Accordion>
                        }
                        </>
                    ))}
                </Grid>
            </div>
        </div>
    )
};


export default HomeTutorial;