import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function RulerNotesModal({ updateType, item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
  const [infoNotes, setInfoNotes] = React.useState(item.rulerNotes);

  let rulerInfo = {
    Id: item.id,
    RulerNotes: infoNotes,
    RulerSerialNumber: item.rulerSerialNumber,
    WarrantyDate: item.warrantyDate,
    UserId: item.userId
  }

  function submitNotes(){
    if (updateType != null){
      dispatch({ type: "UPDATE_SELECTED_COMPANY_RULER", payload: rulerInfo });
    } else {
      dispatch({ type: "UPDATE_RULER", payload: rulerInfo });
    }

    setInfoNotes();
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Ruler Notes
            </Typography>
            <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
              <FormControl sx={{ m: 2 }} style={{}}>
                    <TextField multiline rows={10} value={infoNotes} onChange={(event)=>setInfoNotes(event.target.value)}></TextField>
              </FormControl>
                <Button onClick={() => submitNotes()}>Update Notes</Button>
            </div>
          </Box>
        </Box>
    )
}

export default RulerNotesModal;