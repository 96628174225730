import React from 'react';
import { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    InputLabel,
    Select,
    TextField,
    Box,
    Drawer,
    Hidden,
    List,
    Avatar,
    Divider,
    Typography,
    Badge,
    MenuItem,
    Autocomplete
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';
import { Check, PlusSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function LicensingCreation(selectedCompProp){
    const dispatch = useDispatch();
    const [timeLength, setTimeLength] = React.useState(0);
    const [quant, setQuant] = React.useState();
    const [expirationTime, setExpirationTime] = React.useState(false);
    const [notes, setNotes] = React.useState();
    const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const companyUsers = useSelector((state) => state.CompanyReducer.usersInCompanyMenu);
    const companySelection = useSelector((state) => state.CompanyReducer.selectedCompany);
    const [selectedComp, setSelectedComp] = React.useState(selectedCompProp.selectedCompProp);
    const [selectedUser, setSelectedUser] = React.useState('00000000-0000-0000-0000-000000000000');

    /// sorts array alphabetically for user ease of use
    let allCompaniesArray = allCompanies.sort(function(a, b){
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
        if(nameA < nameB) { return -1; }
        if(nameA > nameB) { return 1; }
        return 0;
    });

    const startDateInitialState = dayjs().startOf('year');
    const endDateInitialState = dayjs().endOf('year');
    const [startDate, setStartDate] = React.useState(startDateInitialState);
    const [endDate, setEndDate] = React.useState(endDateInitialState);
    const [exportStartDate, setExportStartDate] = React.useState(dayjs(startDateInitialState).format());
    const [exportEndDate, setExportEndDate] = React.useState(dayjs(endDateInitialState).format());

    function filterCompany(){
        dispatch({ type: 'GET_COMPANIES_FILTERED', payload: {startDate: exportStartDate, endDate: exportEndDate}});
    };

    function resetCompany(){
        dispatch({ type: 'GET_ALL_COMPANIES' });
    };

    const defaultProps = {
        options: allCompaniesArray,
        getOptionLabel: (option) => option.name,
    };

    let LicenseDTO = {
        LicenseQuantity: quant,
        Expiration: timeLength,
        Notes: notes,
        UserId: selectedUser
    };

    function setSelectedCompany(event){
        setSelectedComp(event)
        dispatch({ type: 'GET_COMPANY_USERS', payload: {event:event , type:2}});
    };

    ///create new license
    function submitLicense(){
        dispatch({ type: "CREATE_LICENSE", payload: LicenseDTO, setNotes, setTimeLength, setQuant, setExpirationTime });
        setExpirationTime(false);
        setQuant();
        setTimeLength();
        setNotes();
    };

    function checkBoxMarker(){
        setExpirationTime(!expirationTime);
        setTimeLength(0);
    }

    useEffect(() => {
        if (selectedCompProp.selectedCompProp != "00000000-0000-0000-0000-000000000000"){
            dispatch({ type: 'GET_COMPANY_USERS', payload: {event:selectedComp , type:2}});
        }
    }, []);

    return(
        <div>
            <h1>Licensing Generator</h1>
            <Form style={{width:"100%", margin: 'auto'}}>
                <Label>Create any number of Licenses with a set expiration length.</Label>
                <br/>
                <Label>A user can be pre assigned to a given license but is not required. Can leave company and user field blank.</Label>
                <br/>
                <br/>
                <FormControl style={{width:'75%', margin:5}}>
                    <Autocomplete
                        disablePortal
                        {...defaultProps}
                        renderInput={(params) => <TextField value={params.id} {...params} label="Companies" />}
                        onChange={(event,newValue) => {setSelectedComp(newValue?.id), setSelectedCompany(newValue), dispatch({ type: 'GET_COMPANY_USERS', payload: {event:newValue?.id , type:2}})}}
                    />
                </FormControl>
                <div style={{width:"100%", margin:'5px', display:'flex', flexDirection:'row'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box>
                            <DatePicker
                                className='DatePicker'
                                views={['year']}
                                label="Year"
                                value={endDate}
                                onChange={ (selectedValue) => {
                                    setStartDate(dayjs(selectedValue).startOf('year'));
                                    setExportStartDate(dayjs(selectedValue).startOf('year').format());
                                    setEndDate(dayjs(selectedValue).endOf('year'));
                                    setExportEndDate(dayjs(selectedValue).endOf('year').format());
                                }}
                                renderInput={ (params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Button variant='contained' style={{ margin:"5px"}} onClick={()=>{filterCompany()}}>Filter Company List</Button>
                    <Button variant='contained' style={{margin:'5px'}} onClick={()=>{resetCompany()}}>Reset Company List</Button>
                </div>
                <br/>
                <FormControl style={{width:'75%', margin:5}}>
                    <InputLabel id="demo-simple-select-label">Users</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedUser}
                        label="Users"
                        onChange={(event) => setSelectedUser(event.target.value)}
                    >
                        {/* <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem> */}
                        {companyUsers.map((item) =>(
                            <MenuItem value={item.id}>{item.firstName} {item.lastName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br/>
                <br/>
                <FormControl sx={{ m: 1 }}>
                    <Label htmlFor="outlined-adornment-amount">License Quantity</Label>
                    <OutlinedInput
                        type="number"
                        id="outlined-adornment-amount"
                        onChange={(event)=>setQuant(event.target.value)}
                    />
                </FormControl>

                {/* <FormControl sx={{m:1}}>
                    <Label htmlFor="outlined-adornment-amount">Expiration Date</Label>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker 
                    value={value} 
                    onChange={(newValue) => setValue(newValue)}
                    />
                    </LocalizationProvider>
                </FormControl> */}

                <FormControl sx={{ m: 1 }}>
                    <Label>Select to generate perptual License, <br/> leave license length blank and check the box.</Label>
                    <Label check>
                        <Input type="switch" role="switch" 
                            checked={expirationTime} 
                            // value={isTax}
                             onChange={() => checkBoxMarker()}
                        />
                            Create Perpetual License
                    </Label>
                </FormControl>
                
                { expirationTime === false ?
                    <FormControl sx={{ m: 1 }}>
                        <Label htmlFor="outlined-adornment-amount">License Time Length</Label>
                        {/* <OutlinedInput
                            type="number"
                            id="outlined-adornment-amount"
                            onChange={(event)=>setTimeLength(event.target.value)}
                            /> */}
                        <Select
                        native
                        value={timeLength}
                        onChange={(event)=>setTimeLength(event.target.value)}
                        >
                            <option aria-label="None" value="" />
                            <option value={31}>30 Day</option>
                            <option value={366}>365 Day</option>
                        </Select>
                    </FormControl>
                    :
                    <div></div>
                }
                <br/>
                <FormControl sx={{ m: 2 }} style={{width:'50%'}}>
                    <Label htmlFor="outlined-adornment-amount">License Notes</Label>
                    <TextField multiline rows={8} value={notes} onChange={(event)=>setNotes(event.target.value)}></TextField>
                </FormControl>
                <br/>
                <Button style={{marginLeft:20, width:"10%"}} color="primary" variant="contained" onClick={()=>submitLicense()}>
                    Generate
                </Button>
            </Form>
        </div>
    )
};

export default LicensingCreation;