import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";

function VideoNameManager({item, style}){
    const dispatch = useDispatch();
    const [vidName, setVidName] = React.useState(item.vidName);

    let newVid ={
        Id: item.id,
        VidUrl: item.vidUrl,
        VidCategory: item.vidCategory,
        VidDescription: item.vidDescription,
        VidOrder: item.vidOrder,
        VidName: vidName,
        VidRoles: item.vidRoles,
    }

    function submitNotes(){
        dispatch({ type: 'UPDATE_VIDEO', payload: newVid});
    
        setVidName();
    };

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
            <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
                Video Name
            </Typography>
            {/* <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p> */}
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <FormControl sx={{ m: 2 }} style={{}}>
                    <TextField multiline rows={4} value={vidName} onChange={(event)=>setVidName(event.target.value)}></TextField>
                </FormControl>
                <Button onClick={() => submitNotes()}>Update Video Name</Button>
            </div>
            </Box>
        </Box>
    )
};

export default VideoNameManager;