import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";
import Page from '../../components/Page';

function AdminConfig(){
    let dispatch = useDispatch();
    const saConfigData = useSelector((state) => state.UserReducer.allConfig);

    const [defaultCompanyRate, setDefaultCompanyRate] = React.useState();
    const [publicAnn, setPublicAnn] = React.useState();
    const [internalAnn, setInternalAnn] = React.useState();
    const [baseServiceFee, setBaseServiceFee] = React.useState();

    function initValues(){
        for (let i = 0; i < saConfigData.length; i++){
            if(saConfigData[i].configName == 'Default Company Rate'){
                setDefaultCompanyRate(saConfigData[i].configValue)
            } else if (saConfigData[i].configName == 'Public Announcements'){
                setPublicAnn(saConfigData[i].configValue)
            } else if (saConfigData[i].configName == 'Internal Announcements'){
                setInternalAnn(saConfigData[i].configValue)
            } else if (saConfigData[i].configName == 'Base Service Fee'){
                setBaseServiceFee(saConfigData[i].configValue)
            }
        }
    }

    let companyRateObj = {
        ConfigName: "Default Company Rate",
        ConfigValue: `${defaultCompanyRate}`
    }

    function updateCompanyRate(){
        dispatch({ type: 'EDIT_S_A_CONF', payload: companyRateObj })
    }

    let publicAnnObj = {
        ConfigName: "Public Announcements",
        ConfigValue: `${publicAnn}`
    }

    function updatePublicAnn(){
        dispatch({ type: 'EDIT_S_A_CONF', payload: publicAnnObj })
    }

    let internalAnnObj = {
        ConfigName: "Internal Announcements",
        ConfigValue: `${internalAnn}`
    }

    function updateInternalAnn(){
        dispatch({ type: 'EDIT_S_A_CONF', payload: internalAnnObj })
    }

    let baseServiceFeeObj = {
        ConfigName: "Base Service Fee",
        ConfigValue: `${baseServiceFee}`
    }

    function updateBaseServiceFee(){
        dispatch({ type: 'EDIT_S_A_CONF', payload: baseServiceFeeObj })
    }
    
    React.useEffect(() => {
        dispatch({ type: 'FETCH_S_A_CONF' });
    },[]);
    React.useEffect(() => {
        initValues();
    },[saConfigData]);
    
    return(
        <Page title="Botmark Config">
        <div>
            <h2>Botmark Admin Configurations</h2>
            <div style={{display:'flex', flexDirection:'column', margin:'10px', width:'25%'}}>   
                <div style={{marginBottom:'15px', display:"flex", flexDirection:'column'}}>
                    <span style={{marginRight:'5px', marginBottom:'5px'}}>Default Company Price Rate:</span>
                    <input style={{marginRight:'5px', marginBottom:'5px'}} onChange={(event)=>setDefaultCompanyRate(event.target.value)} value={defaultCompanyRate}></input>
                    <button onClick={()=>updateCompanyRate()}>Update</button>
                </div>
                <div style={{marginBottom:'15px', display:"flex", flexDirection:'column'}}>
                    <span style={{marginRight:'5px', marginBottom:'5px'}}>Base Service Fee:</span>
                    <input style={{marginRight:'5px', marginBottom:'5px'}} onChange={(event)=>setBaseServiceFee(event.target.value)} value={baseServiceFee}></input>
                    <button onClick={()=>updateBaseServiceFee()}>Update</button>
                </div>
                <div style={{marginBottom:'15px', display:"flex", flexDirection:'column'}}>
                    <span style={{marginRight:'5px', marginBottom:'5px'}}>Public Announcements:</span>
                    <TextField multiline rows={5} style={{marginRight:'5px', marginBottom:'5px'}} onChange={(event)=>setPublicAnn(event.target.value)} value={publicAnn}/>
                    <button onClick={()=>updatePublicAnn()}>Update</button>
                </div>
                <div style={{marginBottom:'15px', display:"flex", flexDirection:'column'}}>
                    <span style={{marginRight:'5px', marginBottom:'5px'}}>Internal Announcements:</span>
                    <TextField multiline rows={5} style={{marginRight:'5px', marginBottom:'5px'}} onChange={(event)=>setInternalAnn(event.target.value)} value={internalAnn}/>
                    <button onClick={()=>updateInternalAnn()}>Update</button>
                </div>
            </div>
        </div>
        </Page>
    )
}

export default AdminConfig;