import React, { useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Input,
    TextField,
    Tabs,
    Tab,
    Autocomplete,
    Checkbox,
    OutlinedInput,
    MenuItem
} from '@mui/material';
import { Folder } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Form, useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function VideoUpload(){
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    const navigate = useNavigate();
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const vids = useSelector((state) => state.FileReducer.vids)
    const vidCats = useSelector((state) => state.FileReducer.vidCats)
    const dispatch = useDispatch();
    const [vidUrl, setVidUrl] = React.useState();
    const [vidType, setVidType] = React.useState('');
    const [vidCat, setVidCat] = React.useState('');
    const [vidCatNumber, setVidCatNumber] = React.useState('');
    const [vidRole, setVidRole] = React.useState('');
    const [vidName, setVidName] = React.useState('');
    const [vidDes, setVidDes] = React.useState('');
    const [newCat, setNewCat] = React.useState('');
    const [roleName, setRoleName] = React.useState([]);
    const [newCatOrder, setNewCatOrder] = React.useState(0);

    const defaultProps = {
        options: vidCats,
        getOptionLabel: (option) => option.videoCategoryName,
    };

    const handleChangeVideoType = (event) => {
        setVidType(Number(event.target.value));
    };

    let newVid ={
        VidUrl: vidUrl,
        VidType: Number(vidType),
        VidCategory: vidCat,
        VidOrder: vidCatNumber,
        // vidRole: vidRole,
        VidName: vidName,
        VidDescription: vidDes,
        VidRoles: JSON.stringify(roleName)
    }

    let newCatObj = {
        VideoCategoryName: newCat,
        VideoCategoryOrder: Number(newCatOrder)
    };

    function uploadVideoCat(){
        dispatch({ type: 'SAVE_VIDEO_CAT', payload: newCatObj});
        setNewCat('');
        setNewCatOrder(0);
    };

    function uploadVideoUrl(){
        dispatch({ type: 'UPLOAD_VIDEO_URL', payload: newVid});
        setVidType('');
        setVidUrl('');
        setVidCat('')
        setVidCatNumber('');
        setVidRole('');
        setVidName('');
        setVidDes('');
        setRoleName([]);
    };

    const names = [
        "Admin",
        "Planner",
        "Download",
        "Downloader Admin",
        "Dealer",
        "Recieved Billing",
        "Billing",
        "Planner Downloader",
        "Sub Super Admin",
        "No Role/ Public"
      ];
      
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setRoleName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    return (
        <Box style={{marginTop:'40px'}}>
            <FormControl>
                <div style={{marginTop:'30px' ,border:'solid black 2px', padding:'10px'}}>
                    <Typography style={{margin:'5px'}}><b>Video Category</b></Typography>
                    <Typography style={{margin:'5px'}}>Create A Video Category to use to Sort Uploaded Videos, this is a way to group similar videos under a shared category.</Typography>
                    <TextField onChange={(event) => setNewCat(event.target.value)} value={newCat}></TextField>
                    <br/>
                    <Typography style={{margin:'5px'}}>Assign a numbered order to the groups to allow them a level of hierarchy on the display page. Categories/groups of videos will appear in order.</Typography>
                    <TextField type="number" onChange={(event) => setNewCatOrder(event.target.value)} value={newCatOrder}></TextField>
                    <br/>
                    <Button 
                        sx={{ width: '50%', margin: "10px" }}
                        variant="contained" 
                        onClick={uploadVideoCat}
                    >
                        Save Category
                    </Button>
                </div>
            </FormControl>
        <Box style={{marginTop:'40px', border:'solid black 2px'}}>
            <Typography style={{margin:'5px'}}><b>YouTube Video</b></Typography>
            <Typography style={{margin:'5px'}}>Please Upload YouTube Video ID. If you copy the url it is the end portion of it after the '='.</Typography>
            <Typography style={{margin:'5px'}}>Example: https://www.youtube.com/watch?v=fey1K2tLwcY ===== fey1K2tLwcY</Typography>
            <FormControl style={{width:'50%'}}>
                <TextField onChange={(event) => setVidUrl(event.target.value)} value={vidUrl}></TextField>
                <br/>
                <Typography style={{margin:'5px'}}>Video Roles</Typography>
                <FormControl>
                    <InputLabel id="demo-multiple-checkbox-label">Video Roles</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={roleName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Video Roles" />}
                        renderValue={(selected) => selected.join(', ')}
                        // MenuProps={MenuProps}
                    >
                    {names.map((name) => (
                        <MenuItem key={name} value={name}>
                        <Checkbox checked={roleName.includes(name)} />
                        <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <br/>
                <Typography style={{margin:'5px'}}>Video Name</Typography>
                <TextField onChange={(event) => setVidName(event.target.value)} value={vidName}></TextField>
                <br/>
                <Typography style={{margin:'5px'}}>Video Description</Typography>
                <TextField multiline maxRows={4} onChange={(event) => setVidDes(event.target.value)} value={vidDes}></TextField>
                <br/>
                <div style={{width:'100%', display:"flex"}}>
                    <div style={{width:'100%'}}>
                        {/* <Typography style={{margin:'5px'}}>Video Category</Typography>
                        <TextField onChange={(event) => setVidCat(event.target.value)} value={vidCat}></TextField> */}
                            <Autocomplete
                                disablePortal
                                {...defaultProps}
                                renderInput={(params) => <TextField value={params.id} {...params} label="Video Category" />}
                                // value={vidCat}
                                onChange={(event,newValue) => {setVidCat(newValue?.id)}}
                            />
                    </div>
                    <div style={{marginLeft:'5px'}}>
                        <TextField label='Video Order' type="number" onChange={(event) => setVidCatNumber(event.target.value)} value={vidCatNumber}></TextField>
                    </div>
                </div>
                <br/>

            </FormControl>
            <br/>
            <Button 
                sx={{ width: '50%', margin: "10px" }}
                variant="contained" 
                onClick={uploadVideoUrl}
            >
                Save Video URL
            </Button>
        </Box>
        </Box>
    )
};

export default VideoUpload;
