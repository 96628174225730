import React, { useEffect } from 'react';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Input,
    TextField,
    Tabs,
    Tab
} from '@mui/material';
import { Folder } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import Toast from "../../components/Toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import "./PDFUpload.css";
import VidTable from "../instructions/TutorialVidDataGrid";
import VideoUpload from "./VideoUpload";
import VideoCategoriesManager from '../instructions/VideoCategoriesManager';
import Page from '../../components/Page';

function PDFUploadPage(){
    ///// Currently vids either require a youtube Id or a embed version of a youtube link to function /////
    ///// PDF Upload form select relevant pdf type to display it in the correct location on the website /////
    const navigate = useNavigate();
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [file, setFile] = React.useState([]);
    const [review, setReview] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    const loading = useSelector((state) => state.FileReducer.isLoading);
    const validation = useSelector((state) => state.FileReducer.file);
    const vids = useSelector((state) => state.FileReducer.vids)
    const dispatch = useDispatch();
    const [agType, setAgType] = React.useState('');
    const [vidUrl, setVidUrl] = React.useState();
    const [vidType, setVidType] = React.useState('');
    const [vidCat, setVidCat] = React.useState('');
    const [vidCatNumber, setVidCatNumber] = React.useState('');
    const [vidRole, setVidRole] = React.useState('');
    const [vidName, setVidName] = React.useState('');
    const [vidDes, setVidDes] = React.useState('');
    const handleChange = (event) => {
        setAgType(Number(event.target.value));
    };
    const handleChangeVideoType = (event) => {
        setVidType(Number(event.target.value));
    };

    let newVid ={
        VidUrl: vidUrl,
        VidType: Number(vidType),
        VidCategory: vidCat,
        VidOrder: vidCatNumber,
        // vidRole: vidRole,
        VidName: vidName,
        VidDescription: vidDes
    }

    React.useEffect(() => {
        if (validation.file.length > 0) {
            setReview(true)
        }
        setFile(acceptedFiles);
        dispatch({ type: 'GET_VIDEO_URLS'});
        dispatch({ type: 'GET_VIDEO_CATS'});
    },[acceptedFiles],[validation.file]);

    const files = acceptedFiles.map(file => (
        <Tooltip key={file.path} title="Click to select different file">
            <ListItem sx={{ border: '1px solid black' }}>
                    <ListItemIcon>
                        <Folder/>
                    </ListItemIcon>
                    <ListItemText>
                        {file.path} - {file.size} bytes
                    </ListItemText>
            </ListItem>
        </Tooltip>
    ));

    function uploadVideoUrl(){
        dispatch({ type: 'UPLOAD_VIDEO_URL', payload: newVid});
        setVidType('');
        setVidUrl('');
        setVidCat('')
        setVidCatNumber('');
        setVidRole('');
        setVidName('');
        setVidDes('');
    };

    const handleUpload = () => {
        if ( file.length < 1 ) return Toast.fire({ title: 'File required', icon: 'error' });
        else if (!file[0].name.includes('.pdf')) return Toast.fire({ title: '.PDF FILES ONLY', icon: 'error' });
        else if (agType == null || agType == undefined) return Toast.fire({ title: 'Please Enter a Categorical Type for this Document.', icon: 'error' });
        else dispatch({ type: 'UPLOAD_PDF_FILE', payload: {file: file[0], name: file[0].name, companyId: company.id, PDFType: agType}, setFile, setAgType, navigate })
    };

    const [value, setValue] = React.useState(0);
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Page title="PDF Upload">
        <Box sx={{margin: "5%"}}>
            <Tabs value={value} onChange={handleTabChange} left style={{ borderBottom: '2px solid darkGray', paddingBottom: '10px' }}>
                <Tab label="Video Management" />
                <Tab label="Video Category Management" />
                <Tab label="YouTube Video Upload" />
                <Tab label="PDF Upload" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <VidTable vids={vids}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <VideoCategoriesManager/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <VideoUpload/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box style={{border:'solid black 2px'}}>
                    <h2 style={{margin:'5px'}}>PDF Uploads</h2>
                    <p>Select Type of Document to assign it to a location on the site.</p>
                    <Typography>License Agreement PDF Upload</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        flexDirection: 'column'
                    }}>
                        <Box 
                            sx={{ 
                                width: '80%',
                                height: '70%',
                                display: 'flex',
                                justifyContent: 'center', 
                                alignItems: 'center',
                                borderWidth: '2px',
                                borderRadius: '2px',
                                borderStyle: 'dashed',
                                padding: '20px',
                                transition: 'border .24s ease-in-out'
                            }}
                            {...getRootProps({className: 'dropzone'})}
                        >
                            <input disabled={company === undefined} {...getInputProps()}/>
                            { acceptedFiles.length > 0 ? <List>{files}</List> : <p>Drag 'n' drop .PDF file here, or click to select a .PDF file</p> }
                        </Box>
                    </Box>
                    <Box sx={{width:"100%", height:"100%", marginTop: '5%'}}>
                        <Typography sx={{}}>Set PDF Type</Typography>
                        <Box component="form" className="pdf" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <FormControl sx={{ m: 2, minWidth: 120 }}>
                                {/* <InputLabel htmlFor="demo-dialog-native">PDF Type</InputLabel> */}
                                <Select
                                    native
                                    onChange={handleChange}
                                    value={agType}
                                    // input={<OutlinedInput label="Status" id="demo-dialog-native" />}
                                >
                                    <option value={0}>Select type</option>
                                    <option value={1}>Upload Agreement PDF</option>
                                    <option value={2}>Download Agreement PDF</option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Button sx={{ width: '50%', margin: "10px" }} 
                            variant="contained" 
                            onClick={handleUpload}
                            disabled={agType == 0}
                        >
                            Upload .PDF
                        </Button>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
        </Page>
    )
};

export default PDFUploadPage;
