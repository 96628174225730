import React from "react";
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from "@mui/x-data-grid";
import {
    Box,
    Hidden,
    Button,
    Typography,
    Chip,    
    Modal,
} from "@mui/material";
import {
    CreditCard,
    CheckCircle,
    Loader,
    FileText,
    Edit2,
    AlertCircle,
    Edit, 
    RefreshCw
} from 'react-feather';
import { useDispatch, useSelector } from "react-redux";
import VideoTutorialRolesView from '../instructions/VideoTutorialRolesView';
import VideoCategoriesManager from "./VideoCategoriesManager";
import VideoCatManager from "./VideoCatManager";
import VideoNameManager from "./VideoNameManager";
import VideoDescriptionManager from "./VideoDescriptionManager";
import VideoUrlManager from "./VideoUrlManager";
import VideoOrderManager from "./VideoOrderManager";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const VidTable = ({vids}) => {
    /// displays all validated plans for a processor
    const dispatch = useDispatch();
    const vidCats = useSelector((state) => state.FileReducer.vidCats)

    const columns = [
        { field: 'vidName', headerName: 'Video Name', flex: .8, headerClassName: 'header', editable: false, 
            renderCell: (cellValues) => {
                return(
                    <Button onClick={() => showInfo1(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{cellValues.row.vidName}</Button>
                )
            }
        },
        { field: 'vidDescription', headerName: 'Video Description', flex: .8, headerClassName: 'header', editable: false, 
            renderCell: (cellValues) => {
                return(
                    <Button onClick={() => showInfo2(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{cellValues.row.vidDescription}</Button>
                )
            }
        },
        { field: 'vidUrl', headerName: 'Video Url', flex: .7, headerClassName: 'header', editable: false,
            renderCell: (cellValues) => {
                return(
                    <Button onClick={() => showInfo3(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{cellValues.row.vidUrl}</Button>
                )
            }
        },
        { field: 'vidType', headerName: 'Video Type', flex: .65, headerClassName: 'header', editable: false, 
            renderCell: (cellValues) => {
                return(
                    <Button onClick={() => showInfo4(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>Assigned Roles</Button>
                )
            }
        },
        { field: 'vidCategory', headerName: 'Video Category', flex: .5, headerClassName: 'header', editable: false,     
            renderCell: (cellValues) => {
                let displayName = '';
                for (let i = 0; i < vidCats.length; i++){
                    if(vidCats[i].id == cellValues.row.vidCategory){
                        displayName = vidCats[i].videoCategoryName;
                    }
                }
                return(
                    <Button onClick={() => showInfo5(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{displayName}</Button>
                    // <span>{displayName}</span>
                )
            }
        },
        { field: 'vidOrder', headerName: 'Video Order', flex: .35, headerClassName: 'header', editable: false, 
            renderCell: (cellValues) => {
                let displayName = '';
                for (let i = 0; i < vidCats.length; i++){
                    if(vidCats[i].id == cellValues.row.vidCategory){
                        displayName = vidCats[i].videoCategoryName;
                    }
                }
                return(
                    <Button onClick={() => showInfo6(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>{cellValues.row.vidOrder}</Button>
                    // <span>{displayName}</span>
                )
            }
        },
        { field: 'delete', headerName: 'Delete', flex: .45, headerClassName: 'header', editable: false, 
            renderCell: (cellValues) => {
                return(
                <Button className="delBtn" variant="contained" sx={{ bgcolor: `error.light`}}
                    onClick={() => {
                        swal({
                            title: "DELETE VIDEO",
                            text: `Delete this video forever? This cannot be undone. Note: Video will still be stored on Youtube.`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: false,
                        })
                        .then((willUpdate) => {
                            if (willUpdate) {
                                dispatch({ type: 'DELETE_VIDEO', payload: cellValues.row.id})
                            } else {
                                swal({
                                    title: `Video not Deleted.`
                                });
                    }})
                }}>
                    Delete
                </Button>    
                )  
            }  
        },
    ]

    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [rowItem, setRowItem] = React.useState();

    function showInfo1(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen1();
    };
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    function showInfo2(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen2();
    };
    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    function showInfo3(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen3();
    };
    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);

    function showInfo4(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen4();
    };
    const [open5, setOpen5] = React.useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);

    function showInfo5(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen5();
    };
    const [open6, setOpen6] = React.useState(false);
    const handleOpen6 = () => setOpen6(true);
    const handleClose6 = () => setOpen6(false);

    function showInfo6(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            vidUrl: cellValues.row.vidUrl,
            vidCategory: cellValues.row.vidCategory,
            vidDescription: cellValues.row.vidDescription,
            vidOrder: cellValues.row.vidOrder,
            vidName: cellValues.row.vidName,
            vidRoles: cellValues.row.vidRoles,
        })
        handleOpen6();
    };

    function CustomToolBar() {
        return (
            <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', p: 1 }}>
                <Hidden smDown>
                    <Box width='40%' textAlign="left">
                        <GridToolbarFilterButton/>
                        <GridToolbarColumnsButton/>
                        <GridToolbarDensitySelector/>
                        <GridToolbarExport/>
                    </Box>
                    <Box width='30%'>
                    </Box>
                </Hidden>
                <Hidden smUp>
                </Hidden>
            </GridToolbarContainer>
        )
    };

    return (
        <Box sx={{ '& .header': { backgroundColor: '#e2e2e2' }, height: '70vh' }}>
            <Typography textAlign={"left"} variant='h4'>
                Tutorial Video Management
            </Typography>
            <DataGrid
                sx={{overflowX:'scroll'}}
                components={{Toolbar: CustomToolBar}}
                columns={columns}
                rows={vids}
            />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoNameManager item={rowItem} style={style}/>
                </Modal>
                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoDescriptionManager item={rowItem} style={style}/>
                </Modal>
                <Modal
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoUrlManager item={rowItem} style={style}/>
                </Modal>
                <Modal
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoTutorialRolesView item={rowItem} style={style}/>
                </Modal>
                <Modal
                    open={open5}
                    onClose={handleClose5}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoCatManager item={rowItem} style={style}/>
                </Modal>
                <Modal
                    open={open6}
                    onClose={handleClose6}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <VideoOrderManager item={rowItem} style={style}/>
                </Modal>
        </Box>
    )
};

export default VidTable;