import React from 'react';
import Page from '../../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import { Check, PlusSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

// TODO: add in user roles
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <Hidden smDown>
                <GridToolbarFilterButton/>
                <GridToolbarDensitySelector/>
            </Hidden>
            <GridToolbarExport/>
        </GridToolbarContainer>
    )
};

function PriceOptionsDatagrid(){
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const role = useSelector((state) => state.CompanyReducer.userRole);
    const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    const CompanyPrices = useSelector((state) => state.CompanyReducer.CompanyPrices);
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies);
    const userData = useSelector((state)=> state.UserReducer.user);
    const BotmarkPrimaryPrices = useSelector((state)=> state.CompanyReducer.BotmarkPrices);

    const [editRowsModel, setEditRowsModel] = React.useState({});
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    ///allows for editing the datagrid
    const handleSave = () => {
        const objId = Object.keys(editRowsModel)[0];
        const obj = Object.values(editRowsModel)[0];
        console.log('object here', obj)
        const item = {
            id: objId,
            ItemName: obj.itemName.value,
            Description: obj.description.value,
            Rate: obj.rate.value,
            Unit: obj.unit.value,
            CompanyId: userCompany[0].id
        };
        console.log("ROW ITEM HERE",item)
        dispatch({type: "UPDATE_PRICE", payload: item});
    };

    let allPrices = [];
    let columns = [];
    if (userData.isAdmin){
        allPrices = [...CompanyPrices, ...BotmarkPrimaryPrices];
    } else {
        allPrices = [...CompanyPrices];
    }

    /// allows for different data display for super adins and normal users
    if (userData.isAdmin == true){
        columns = [
            { field: 'itemName', headerName: 'Item', flex: .75, headerClassName: 'header', editable: true },
            { field: 'description', headerName: 'Description', flex: 1.25, headerClassName: 'header', editable: true },
            { field: 'unit', headerName: 'Unit', flex: .5, headerClassName: 'header', editable: true },
            { field: 'rate', headerName: 'Rate', flex: .5, headerClassName: 'header', editable: true },
            { field: 'isForTaxes', headerName: 'Is Tax', flex: .5, headerClassName: 'header', renderCell: (params) => (
                isForTaxes(params.row)
            )},
            { field: 'isBotmarkPrimary', headerName: 'Botmark Fee', flex: .5, headerClassName: 'header', renderCell: (params) => (
                isBotmarkPrimary(params.row)
            )},
            { field: 'Remove', flex: .5, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> {      
                    return(  
                        <Button color="error" onClick={() => deletePrice(cellValues)}>Delete</Button>
                    )
                }
            },
        ];
    } else {
        columns = [
            { field: 'itemName', headerName: 'Item', flex: .75, headerClassName: 'header', editable: true },
            { field: 'description', headerName: 'Description', flex: 1.25, headerClassName: 'header', editable: true },
            { field: 'unit', headerName: 'Unit', flex: .5, headerClassName: 'header', editable: true },
            { field: 'rate', headerName: 'Rate', flex: .5, headerClassName: 'header', editable: true },
            { field: 'isForTaxes', headerName: 'Is Tax', flex: .5, headerClassName: 'header', renderCell: (params) => (
                isForTaxes(params.row)
            )},
            { field: 'Remove', flex: .5, headerClassName: 'header', sortable: false,
                renderCell: (cellValues)=> {      
                    return(  
                        <Button color="error" onClick={() => deletePrice(cellValues)}>Delete</Button>
                    )
                }
            },
        ];
    }

    function isBotmarkPrimary(x) {
        if ( x.isBotmarkPrimary === true ) {
            return (
                <Button variant="contained" color="success">
                    True
                </Button>
            )
        }
        if (x.isBotmarkPrimary === false) {
            return(
                <Button variant="contained" color="secondary">
                    False
                </Button>
            )
        }
    };

    function isForTaxes(x) {
        if ( x.isForTaxes === true ) {
            return (
                <Button variant="contained" color="primary">
                    True
                </Button>
            )
        }
        if (x.isForTaxes === false) {
            return(
                <Button variant="contained" color="secondary">
                    False
                </Button>
            )
        }
    };

    ///delete price option
    function deletePrice(cellValues){
        dispatch({ type: 'DELETE_PRICE', payload: {id:cellValues.row.id, CompanyId: userCompany[0].id} });
    }

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    return (
        <div>
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Price Options
                    </Typography>
                </Box>
                <Box textAlign={"left"}>
                    <Typography textAlign={"left"} color={"blue"} variant='h4'>
                        Editing Mode
                    </Typography>
                    <Typography textAlign={"left"} variant='body2'>
                        Double click to enter row, hit enter or click out to save changes. <br/> Press Esc to cancel editing a field.
                    </Typography>
                </Box>
                <DataGrid
                    editMode="row"
                    onRowEditCommit={handleSave}
                    editRowsModel={editRowsModel}
                    onEditRowsModelChange={handleEditRowsModelChange}
                    columns={columns}
                    rows={allPrices}
                    sx={{ height: '70vh' }}
                />
            </Box>
        </div>
    )
};

export default PriceOptionsDatagrid;