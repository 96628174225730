import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField
} from "@mui/material";

function VideoOrderManager({item, style}){
    const dispatch = useDispatch();
    const [vidOrder, setVidOrder] = React.useState(item.vidOrder);

    let newVid ={
        Id: item.id,
        VidUrl: item.vidUrl,
        VidCategory: item.vidCategory,
        VidDescription: item.vidDescription,
        VidOrder: vidOrder,
        VidName: item.vidName,
        VidRoles: item.vidRoles,
    }

    function submitNotes(){
        dispatch({ type: 'UPDATE_VIDEO', payload: newVid});
    
        setVidOrder();
    };

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
            <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
                Video Order
            </Typography>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <FormControl sx={{ m: 2 }} style={{}}>
                    <TextField label='Video Order' type="number" value={vidOrder} onChange={(event)=>setVidOrder(event.target.value)}></TextField>
                </FormControl>
                <Button onClick={() => submitNotes()}>Update Video Order</Button>
            </div>
            </Box>
        </Box>
    )
};

export default VideoOrderManager;