import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    FormControl,
    InputLabel,
    Button,
    Tab,
    Tabs,
    inputAdornmentClasses,
    Menu,
    MenuItem,
    Autocomplete
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { useEffect } from "react";
import moment from 'moment';
import PointListTable from "./PointListTable";
// import ReactPDF from '@react-pdf/renderer'
// import {PDFViewer} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import BotmarkEstimateDataView from "./BotmarkEstimateDataView";

function BotmarkEstimateView({ botmarkEstimateObj, handleCloseBotmarkEstimate }) {
    /// auto generation of botmark invoice for processors
    const saConfigData = useSelector((state) => state.UserReducer.allConfig);
    const file = useSelector((state) => state.FileReducer.file.info);
    const fileReport = useSelector((state) => state.FileReducer.file);
    const userData = useSelector((state) => state.UserReducer.user);
    const BotmarkPrimaryPrices = useSelector((state) => state.CompanyReducer.BotmarkPrices);
    const company = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const dispatch = useDispatch();

    const [invoiceOption, setInvoiceOption] = React.useState([]);
    const [botmarkTotal, setBotmarkTotal] = React.useState(0);
    const [pointTypeTotal, setPointTypeTotal] = React.useState(0);
    const [specialPointTypeTotal, setSpecialPointTypeTotal] = React.useState(0);
    const customerSelect = useSelector((state) => state.UserReducer.customerSelection);
    const [customer, setCustomer] = React.useState('');
    const [selectedCustomer, setSelectedCustomer] = React.useState();

    let data = [];
    data = JSON?.parse(botmarkEstimateObj?.botmarkInvoiceValues);
    console.error(data);

    const [baseServiceFee, setBaseServiceFee] = React.useState(0);

    function initValues(){
        for (let i = 0; i < saConfigData?.length; i++){
            if (saConfigData[i]?.configName == 'Base Service Fee'){
                setBaseServiceFee(saConfigData[i]?.configValue);
            }
        }
    }


    let pointList = "";
    if(botmarkEstimateObj?.pointList != ""){
        pointList = JSON.parse(botmarkEstimateObj?.pointList);
    }
    let finalTotal = 0;

    //
    /// For File name selection in invoice ///
    //
    function getLastItem(thePath) {
        if (thePath == null || thePath == "") return "";
        var index = thePath.lastIndexOf('/') + 1;
        return thePath.substring(index);
    }

    let pointTotal = 0;
    let tempTotal = 0;
    let specialPointTotal = 0;
    
    function pointCounter(){
        for (let i = 0; i < pointList?.length; i++) {
            if (pointList[i].TypeEnum == 1 || pointList[i].TypeEnum == 2 || pointList[i].TypeEnum == 3 ){
                pointTotal += pointList[i].Quantity;
            }
            console.error(pointList)
            if (pointList[i].TypeEnum == 4){
                if (pointList[i].ShouldCharge == true && pointList[i].Enabled == true){
                    specialPointTotal += pointList[i].Quantity;
                }
            }
        }
        setPointTypeTotal(pointTotal);
        setSpecialPointTypeTotal(specialPointTotal);
    }

    /// This sets the dropdown to select a customer for a plan in invoicing ///
    const allCustomers = useSelector((state) => state.CompanyReducer.allCompanyMessages);
    const customers = [{id: '00000000-0000-0000-0000-000000000000', userCompany:'None'}];
    allCustomers?.forEach(customerPusher);
    function customerPusher(allCustomers) {
        if (allCustomers.requestStatus === 1 || allCustomers.requestStatus === 2){
        customers.push({
            id: allCustomers.id,
            companyId: allCustomers.companyId,
            created: allCustomers.created,
            deleted: allCustomers.deleted,
            message: allCustomers.message,
            requestStatus: allCustomers.requestStatus,
            userId: allCustomers.userId,
            userEmail: allCustomers.userEmail,
            customerName: allCustomers.customerName,
            userCompany: allCustomers.companyContact,
            companyStatus: allCustomers.companyStatus,
            userCompanyId: allCustomers.userCompanyId,
            companyRate: allCustomers.companyRate
        })}
    };

    /// Handle assignment of a customer to a plan ///
    const handleChange = (event) => {
        setCustomer(event.target.value);
    };
    
    function setCustomertoFile(value){
        if (customer != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (customer == customers[i].userCompanyId){
                    setSelectedCustomer(customers[i]);
                }
            }
        } else {
            setSelectedCustomer();
        }
        dispatch({ type: 'SET_CUSTOMER_FOR_FILE', payload: {file: file.id, customer, userCompany: company.id} });
    };
    
    function initCustomer(){
        setCustomer(botmarkEstimateObj.customerId);
        console.log(botmarkEstimateObj.customerId)
        if (customer != '00000000-0000-0000-0000-000000000000'){
            for (let i = 0; i < customers.length; i++){
                if (botmarkEstimateObj.customerId == customers[i].userCompanyId){
                    setSelectedCustomer(customers[i]);
                    console.log(customers[i])
                }
            }
        }
    };

    const [results, setResults] = React.useState(fileReport.file);
    const [value, setValue] = React.useState(0);
    const [summary, setSummary] = React.useState(fileReport.summary);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                {...other}
            >
                <Box p={3}>
                    {children}
                </Box>
            </div>
        )
    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {  
        setResults(fileReport.file);
        setSummary(fileReport.summary);
    }, [fileReport.file, fileReport.summary]);
    
    React.useEffect(()=>{
        pointCounter();
        initCustomer();
        initValues();
    },[company] ,[file] , [customers], [selectedCustomer], [pointTypeTotal])

    const defaultProps = {
        options: customers,
        getOptionLabel: (option) => option.userCompany,
    };

    return (
        <Container style={{ backgroundColor: 'lightgray', maxWidth: '100vw', position: 'fixed', height: '100%' }}>
            <Container className='estimatePage' maxWidth="sm" style={{ padding: 20, width: '100vw', display: 'flex', flexDirection: 'column', overflowY: 'scroll', height:'inherit' }}>
        <Box sx={{ width: '100%' }}>
                <Tabs value={value} onChange={handleTabChange} centered style={{borderBottom:'2px solid black', paddingBottom:'10px'}}>
                    <Tab label="Estimate Review"/>
                    {botmarkEstimateObj.isProcessed == 1 &&
                        <Tab label="Validation & Summary"/>
                    }
                </Tabs>
                <TabPanel value={value} index={0} style={{}}>
                    <Container>
                        <Typography variant="h4" align="right">
                            Estimate Review
                        </Typography>
                        <p style={{ display: "flex", justifyContent: 'right', width: "100%" }}>
                            <span style={{ marginRight: 50 }}>Date: {moment().format("MM/DD/YYYY")}</span>
                            <span># {data?.estimateNumber}</span>
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, margin: 10 }}>
                                <div style={{ flex: 1, margin: 2 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>
                                        <h4 style={{ display: "flex", justifyContent: 'left' }}>From Botmark:</h4>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Botmark Inc.</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>7945 168 Ave SE</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Mooreton, North Dakota</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>58061, United States</span></span>
                                    </div>
                                </div>
                                <div style={{ flex: 1, margin: 10 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: 10 }}>

                                        <h4 style={{ display: "flex", justifyContent: 'left' }}>To Processor:</h4>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {company.name}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {userData?.firstName} {userData?.lastName}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>{userData?.email}</span></span>
                                        <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Phone: {company?.phoneNumber}</span></span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1, margin: 10 }}>
                                { company.processExternal == true &&
                                    <Box>
                                        {botmarkEstimateObj.isProcessed == 1 &&
                                            <form style={{display:'flex', flexDirection:'column'}}>
                                                <h4>Customer Select</h4>
                                                <p>Select customer to set any applicable pricing options and to generate customer estimate upon acceptance of this estimate.</p>

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={customer}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={'00000000-0000-0000-0000-000000000000'}>None</MenuItem>
                                                    {customers.map((item) =>(
                                                        <MenuItem value={item?.userCompanyId}>
                                                            {item?.requestStatus == 0 &&
                                                                <span style={{color:'purple'}}>{item?.userCompany} : {item?.customerName}</span>
                                                            }
                                                            {item?.requestStatus == 1 &&
                                                                <span style={{color:'green'}}>{item?.userCompany} : {item?.customerName}</span>
                                                            }
                                                            {item?.requestStatus == 2 &&
                                                                <span style={{color:'darkOrange'}}>{item?.userCompany} : {item?.customerName}</span>
                                                            }
                                                            {item?.requestStatus == 3 &&
                                                                <span style={{color:'red'}}>{item?.userCompany} : {item?.customerName}</span>
                                                            }
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <Button onClick={()=>setCustomertoFile()}>Update Customer</Button>
                                            </form>
                                        }
                                        { selectedCustomer != undefined &&
                                            <div>
                                                <h4 style={{ display: "flex", justifyContent: 'left' }}>For Customer:</h4>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Company: {selectedCustomer?.userCompany}</span></span>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>User: {selectedCustomer?.customerName}</span></span>
                                                <span style={{ display: "flex", justifyContent: "space-between", width: "100%", margin: 5 }}><span>Email: {selectedCustomer?.userEmail}</span></span>
                                            </div>
                                        }
                                    </Box>
                                }
                            </div>
                            <PointListTable pointList={pointList} name={botmarkEstimateObj.name} id={botmarkEstimateObj.id} pointTypeTotal={pointTypeTotal}/>
                        </div>
                        <br />
                        <Typography variant="body1">
                            <b>Estimate</b>
                        </Typography>
                        <BotmarkEstimateDataView baseServiceFee={baseServiceFee} customer={customer} handleCloseBotmarkEstimate={handleCloseBotmarkEstimate} userData={userData} finalTotal={finalTotal} specialPointTypeTotal={specialPointTypeTotal} pointTypeTotal={pointTypeTotal} botmarkEstimateObj={botmarkEstimateObj} invoiceOption={invoiceOption} botmarkTotal={botmarkTotal} company={company} selectedCustomer={selectedCustomer}/>
                        <br />
                    </Container>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Box m={2} sx={{  display: 'flex', flexDirection:'row', justifyContent: 'center',height: '100%', minHeight: '300px'}}>
                        <div style={{flex:2, margin: 20, overflowX:'scroll'}}>
                            <h4 style={{textAlign:'left'}}>Validation:</h4>
                            <pre style={{textAlign:'left'}}>
                                {results}
                            </pre>
                        </div>
                        <div style={{flex:1, margin:20}}>
                            <h4 style={{textAlign:'left'}}>Summary:</h4>
                                <pre style={{textAlign:'left'}}>
                                    {summary}
                                </pre>
                        </div>
                    </Box> 
                </TabPanel>
            </Box>
            </Container>
        </Container>
    )
}

export default BotmarkEstimateView;