import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    FormControl,
    InputLabel,
    Button,
    Tab,
    Tabs,
    inputAdornmentClasses,
    Menu,
    MenuItem,
    InputAdornment,
    Input,
    OutlinedInput
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { useEffect } from "react";
import moment from 'moment';
import PointListTable from "./PointListTable";
import ReactDOM from 'react-dom';

function BotmarkEstimateDataView({
    specialPointTypeTotal, 
    pointTypeTotal, 
    botmarkEstimateObj, 
    invoiceOption, 
    botmarkTotal, 
    company, 
    selectedCustomer ,
    userData,
    handleCloseBotmarkEstimate,
    customer,
    baseServiceFee
}) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.UserReducer.isAuthenticated);

    console.error("OBJ", botmarkEstimateObj);

    let data = [];
    data = JSON?.parse(botmarkEstimateObj?.botmarkInvoiceValues);
    let invoiceTotal = 0;
    let taxTotal = 0;
    let finalTotal = 0;
    // let pointList = JSON.parse(botmarkEstimateObj?.pointList);
    let invoiceData = {};
    const [planCredit, setPlanCredit] = React.useState(0);
    const [creditReason, setCreditReason] = React.useState("");

    if (selectedCustomer == undefined){
        if(botmarkEstimateObj.isProcessed == 1){
            if (specialPointTypeTotal == 0){
                invoiceData = {
                    date: moment().format("MM/DD/YYYY"),
                    estimateNumber: data.estimateNumber,
                    items: [
                        {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: company.companyRate, quantity: pointTypeTotal, total: company.companyRate*pointTypeTotal}
                    ],
                    taxes: [],
                    creditAdjustment: planCredit,
                    finalTotal: Number((company.companyRate*pointTypeTotal)-planCredit).toFixed(2),
                    pointTypeTotal: pointTypeTotal,
                    customerInfo: selectedCustomer,
                    processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                    botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                };
            } else {
                invoiceData = {
                    date: moment().format("MM/DD/YYYY"),
                    estimateNumber: data.estimateNumber,
                    items: [
                        {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: company.companyRate, quantity: pointTypeTotal, total: company.companyRate*pointTypeTotal},
                        {itemName: "CL Points", description: "Chargeable Special Points", unit: 'per/point', rate: company.companyRate, quantity: specialPointTypeTotal, total: company.companyRate*specialPointTypeTotal},
                    ],
                    taxes: [],
                    creditAdjustment: planCredit,
                    finalTotal: Number(((company.companyRate*pointTypeTotal)+(company.companyRate*specialPointTypeTotal))-planCredit).toFixed(2),
                    pointTypeTotal: pointTypeTotal,
                    customerInfo: selectedCustomer,
                    processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                    botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                };
            }
        } else {
            invoiceData = {
                date: moment().format("MM/DD/YYYY"),
                estimateNumber: data.estimateNumber,
                items: [
                    {itemName: "Botmark Base Service Fee", description: "Manual Processing", unit: '', rate: Number(baseServiceFee), quantity: 1, total: Number(baseServiceFee)}
                ],
                taxes: [],
                creditAdjustment: planCredit,
                finalTotal: Number((baseServiceFee)-planCredit).toFixed(2),
                pointTypeTotal: pointTypeTotal,
                customerInfo: selectedCustomer,
                processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
            };
        }
    } else {
        if(botmarkEstimateObj.isProcessed == 1){
            if (specialPointTypeTotal == 0){
                if (planCredit != 0){
                    invoiceData = {
                        date: moment().format("MM/DD/YYYY"),
                        estimateNumber: data.estimateNumber,
                        items: [
                            {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: pointTypeTotal, total: selectedCustomer.companyRate*pointTypeTotal},
                            {itemName: "Credit", description: creditReason, unit: 'special', rate: -planCredit, quantity: 1, total: -planCredit}
                        ],
                        taxes: [],
                        creditAdjustment: planCredit,
                        finalTotal: Number((selectedCustomer.companyRate*pointTypeTotal)-planCredit).toFixed(2),
                        pointTypeTotal: pointTypeTotal,
                        customerInfo: selectedCustomer,
                        processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                        botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                    };
                } else {
                    invoiceData = {
                        date: moment().format("MM/DD/YYYY"),
                        estimateNumber: data.estimateNumber,
                        items: [
                            {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: pointTypeTotal, total: selectedCustomer.companyRate*pointTypeTotal},
                        ],
                        taxes: [],
                        creditAdjustment: planCredit,
                        finalTotal: Number((selectedCustomer.companyRate*pointTypeTotal)-planCredit).toFixed(2),
                        pointTypeTotal: pointTypeTotal,
                        customerInfo: selectedCustomer,
                        processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                        botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                    };
                }
            } else {
                if (planCredit != 0){
                    invoiceData = {
                        date: moment().format("MM/DD/YYYY"),
                        estimateNumber: data.estimateNumber,
                        items: [
                            {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: pointTypeTotal, total: selectedCustomer.companyRate*pointTypeTotal},
                            {itemName: "CL Points", description: "Chargeable Special Points", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: specialPointTypeTotal, total: selectedCustomer.companyRate*specialPointTypeTotal},
                            {itemName: "Credit", description: creditReason, unit: 'special', rate: -planCredit, quantity: 1, total: -planCredit}
                        ],
                        taxes: [],
                        creditAdjustment: planCredit,
                        finalTotal: Number(((selectedCustomer.companyRate*pointTypeTotal)+(selectedCustomer.companyRate*specialPointTypeTotal)-planCredit)).toFixed(2),
                        pointTypeTotal: pointTypeTotal,
                        customerInfo: selectedCustomer,
                        processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                        botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                    };
                } else {
                    invoiceData = {
                        date: moment().format("MM/DD/YYYY"),
                        estimateNumber: data.estimateNumber,
                        items: [
                            {itemName: "BM Points", description: "E+C+U", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: pointTypeTotal, total: selectedCustomer.companyRate*pointTypeTotal},
                            {itemName: "CL Points", description: "Chargeable Special Points", unit: 'per/point', rate: selectedCustomer.companyRate, quantity: specialPointTypeTotal, total: selectedCustomer.companyRate*specialPointTypeTotal},
                        ],
                        taxes: [],
                        creditAdjustment: planCredit,
                        finalTotal: Number(((selectedCustomer.companyRate*pointTypeTotal)+(selectedCustomer.companyRate*specialPointTypeTotal)-planCredit)).toFixed(2),
                        pointTypeTotal: pointTypeTotal,
                        customerInfo: selectedCustomer,
                        processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                        botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
                    };

                }
            }
        } else {
            invoiceData = {
                date: moment().format("MM/DD/YYYY"),
                estimateNumber: data.estimateNumber,
                items: [
                    {itemName: "Botmark Base Service Fee", description: "Manual Processing", unit: '', rate: Number(baseServiceFee), quantity: 1, total: Number(baseServiceFee)}
                ],
                taxes: [],
                creditAdjustment: planCredit,
                finalTotal: Number((baseServiceFee)-planCredit).toFixed(2),
                pointTypeTotal: pointTypeTotal,
                customerInfo: selectedCustomer,
                processorInfo: {name: company?.name, street: company?.street, city: company?.city, state: company?.state, zip: company?.zip, country: company?.country, email: company?.email, companyStatus: company?.companyStatus, phoneNumber: company?.phoneNumber, processorName: `${userData?.firstName} ${userData?.lastName}`},
                botmarkInfo: {name:'Botmark Inc.', street:'7945 168 Ave SE', city:'Mooreton', state: 'North Dakota', zip:'58061', country: 'United States'}
            };
        }
    }

    //
    /// dispatch data ///
    //
    let sendInvoiceObject = {
        Id: botmarkEstimateObj.botmarkEstimateId,
        CustomerId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        DeveloperId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        InvoiceValues: JSON.stringify(invoiceData),
        OpenInvoice: true,
        JobId: botmarkEstimateObj.id,
        isSent: true,
        isInvoiceAccepted: parseInt(2)
    };
    let sendForCustomerInvoiceObject = {
        Id: botmarkEstimateObj.botmarkEstimateId,
        CustomerId: botmarkEstimateObj.botmarkInvoiceCustomerId,
        DeveloperId: botmarkEstimateObj.botmarkInvoiceDeveloperId,
        InvoiceValues: JSON.stringify(invoiceData),
        OpenInvoice: true,
        JobId: botmarkEstimateObj.id,
        isSent: true,
        isInvoiceAccepted: parseInt(4)
    };

    //
    //// Send Estimate with Personal Use Paramaters ////
    //
    function acceptEstimate() {
        dispatch({ type: "CREATE_INVOICE", payload: sendInvoiceObject });
        handleCloseBotmarkEstimate();
    }
    
    //
    //// Send Estimate with Customer Use Paramaters ////
    //
    function acceptForCustomerEstimate() {
        dispatch({ type: "CREATE_INVOICE", payload: sendForCustomerInvoiceObject });
        handleCloseBotmarkEstimate();
    }
    
    //
    //// Logic to decide which payload to send for estiamte acceptance
    //
    function estimateAcceptanceLogic(){
        if(customer == "00000000-0000-0000-0000-000000000000"){
            console.log("PROCESSOR ACCEPT");
            swal({
                title: "ACCEPT ESTIMATE",
                text: `Estimate is being accepted for ${company.name}'s Internal Use, once accepted this cannot be changed. Is this Correct?`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    acceptEstimate();
                } else {
                    swal({
                        title: `Estimate not Accepted.`
                    });
            }})
        } else {
            console.log("CUSTOMER ACCEPT");
            swal({
                title: "ACCEPT ESTIMATE",
                text: `Estimate is being accepted for ${selectedCustomer.userCompany}: ${selectedCustomer.customerName}, once accepted this cannot be changed. Is this Correct?`,
                icon: "warning",
                buttons: true,
                dangerMode: false,
            })
            .then((willUpdate) => {
                if (willUpdate) {
                    acceptForCustomerEstimate();
                } else {
                    swal({
                        title: `Estimate Not Accepted.`
                    });
            }})
        }
    };

    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow><TableCell colSpan={6}></TableCell></TableRow>
                    <TableCell className="estimateTableCellVariant">Item</TableCell>
                    <TableCell className="estimateTableCellVariant">Description</TableCell>
                    <TableCell className="estimateTableCellVariant">Qty</TableCell>
                    <TableCell className="estimateTableCellVariant">U/M</TableCell>
                    <TableCell className="estimateTableCellVariant">Rate</TableCell>
                    <TableCell className="estimateTableCellVariant">Amount</TableCell>
                </TableHead>
                    <TableBody>
                    { botmarkEstimateObj.isProcessed == 1 ?
                        <TableRow>
                            <TableCell className="estimateTableCellVariant">BM Points</TableCell>
                            <TableCell className="estimateTableCellVariant">E+C+U</TableCell>
                            <TableCell className="estimateTableCellVariant">{pointTypeTotal}</TableCell>
                            <TableCell className="estimateTableCellVariant">per/point</TableCell>
                            {selectedCustomer == undefined ?
                                <TableCell className="estimateTableCellVariant">{company.companyRate}</TableCell>
                                :
                                <TableCell className="estimateTableCellVariant">{selectedCustomer.companyRate}</TableCell>
                            }
                            {selectedCustomer == undefined ?
                                <TableCell className="estimateTableCellVariant"> 
                                    <CurrencyFormat 
                                        value={Number(pointTypeTotal) * company.companyRate}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    >
                                    </CurrencyFormat>
                                </TableCell>
                                :
                                <TableCell className="estimateTableCellVariant"> 
                                    <CurrencyFormat 
                                        value={Number(pointTypeTotal) * selectedCustomer.companyRate}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    >
                                    </CurrencyFormat>
                                </TableCell>
                            }
                        </TableRow>
                        :
                        <TableRow>
                            <TableCell className="estimateTableCellVariant"><b>Botmark Base Service Fee</b></TableCell>
                            <TableCell className="estimateTableCellVariant"><span>Manual Processing</span></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant">
                                <CurrencyFormat 
                                    value={Number(baseServiceFee)}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                >
                                </CurrencyFormat>
                            </TableCell>
                        </TableRow>
                    }
                    { specialPointTypeTotal != 0 &&
                        <TableRow>
                        <TableCell className="estimateTableCellVariant">CL Points</TableCell>
                        <TableCell className="estimateTableCellVariant">Chargeable Special Points</TableCell>
                        <TableCell className="estimateTableCellVariant">{specialPointTypeTotal}</TableCell>
                        <TableCell className="estimateTableCellVariant">per/point</TableCell>
                        {selectedCustomer == undefined ?
                            <TableCell className="estimateTableCellVariant">{company.companyRate}</TableCell>
                            :
                            <TableCell className="estimateTableCellVariant">{selectedCustomer.companyRate}</TableCell>
                        }
                        {selectedCustomer == undefined ?
                            <TableCell className="estimateTableCellVariant"> 
                                <CurrencyFormat 
                                    value={Number(specialPointTypeTotal) * company.companyRate}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                >
                                </CurrencyFormat>
                            </TableCell>
                            :
                            <TableCell className="estimateTableCellVariant"> 
                                <CurrencyFormat 
                                    value={Number(specialPointTypeTotal) * selectedCustomer.companyRate}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                >
                                </CurrencyFormat>
                            </TableCell>
                        }
                    </TableRow>
                    }
                    {user.theUserRoles[0] == "SSA" &&
                        <TableRow>
                            <TableCell className="estimateTableCellVariant"><b>Plan Credit:</b></TableCell>
                            <TableCell className="estimateTableCellVariant"><input onChange={(event) => setCreditReason(event.target.value)} value={creditReason}/></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant" style={{width:'10vw'}}>
                                    <input onChange={(event) => setPlanCredit(event.target.value)} value={planCredit}/>
                            </TableCell>
                        </TableRow>
                    }
                    {user.isAdmin == true &&
                        <TableRow>
                            <TableCell className="estimateTableCellVariant"><b>Plan Credit:</b></TableCell>
                            <TableCell className="estimateTableCellVariant"><input style={{width:'100%'}} onChange={(event) => setCreditReason(event.target.value)} value={creditReason}/></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant" style={{width:'10vw'}}>
                                    <input onChange={(event) => setPlanCredit(event.target.value)} value={planCredit}/>
                            </TableCell>
                        </TableRow>
                    }
                    {botmarkEstimateObj.isProcessed == 1 ?
                        <TableRow>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"><b>Total:</b></TableCell>
                            <TableCell className="estimateTableCellVariant">
                            {selectedCustomer == undefined ?
                                    <CurrencyFormat 
                                        value={Number(((pointTypeTotal+specialPointTypeTotal) * company.companyRate) - planCredit).toFixed(2)}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                                :
                                    <CurrencyFormat 
                                        value={Number(((pointTypeTotal+specialPointTypeTotal) * selectedCustomer.companyRate) - planCredit).toFixed(2)}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />
                            }
                            </TableCell>
                        </TableRow>
                        :
                        <TableRow>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"></TableCell>
                            <TableCell className="estimateTableCellVariant"><b>Total:</b></TableCell>
                            <TableCell className="estimateTableCellVariant">
                                <CurrencyFormat 
                                    value={Number(baseServiceFee - planCredit).toFixed(2)}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <span>Accepting Estimate: Agree to payment amount and generate Estimate. Plans will become avalible after Estimate has been Accepted.</span>
                    <br />
                    <Button onClick={() => handleCloseBotmarkEstimate()}>Back</Button>
                    {selectedCustomer == undefined ?
                        <>
                            {company.companyRate == 0 ?
                            <>
                                <Button disabled onClick={() => estimateAcceptanceLogic()}>Accept</Button>
                                <br/>
                                <span>Contact Botmark to set up Pricing Rate</span>
                            </>
                            :
                            <Button onClick={() => estimateAcceptanceLogic()}>Accept</Button>
                            }        
                        </>
                        :
                        <>
                            {selectedCustomer.companyRate == 0 ?
                                <>
                                    <Button disabled onClick={() => estimateAcceptanceLogic()}>Accept</Button>
                                    <br/>
                                    <span>Contact Botmark to set up Pricing Rate for Customer</span>
                                </>                 
                            :
                                <Button onClick={() => estimateAcceptanceLogic()}>Accept</Button>
                            }        
                        </>
                    }
        </div>
    )
}

export default BotmarkEstimateDataView;